import axios from 'axios'
import React from 'react'
import { AuthContext } from '../../contexts/Auth'
import { ThemeContext } from '../../contexts/Theme'
import { UserContext } from '../../contexts/User'
import validateError from '../../utils/auth'
import { normalizeUserData } from './Normalizers'

const { CancelToken } = axios

export const useSettingsUser = () => {
  const [, dispatchUserContext] = React.useContext(UserContext)
  const [, dispatchThemeContext] = React.useContext(ThemeContext)

  const [stateAuthContext] = React.useContext(AuthContext)
  const { token } = stateAuthContext

  const [requestLoading, setRequestLoading] = React.useState(false)
  const [requestError, setRequestError] = React.useState(false)
  const [requestData, setRequestData] = React.useState({})

  const {
    accessRoles,
    defaultScreen,
    userEmail,
    userId,
    userLanguage,
    userName,
    userTheme,
    userGroup,
    commercialId,
    company,
    companyId,
    allCurrencies,
    userXCompanyList,
    userXProductList,
  } = requestData || {}
  const { billingCategory } = company || {}

  React.useEffect(() => {
    if (
      accessRoles &&
      defaultScreen &&
      userEmail &&
      userId &&
      userLanguage &&
      userName &&
      userGroup &&
      userXCompanyList &&
      userXProductList
    ) {
      dispatchUserContext({
        type: 'setSettingsUser',
        accessRoles,
        defaultScreen,
        userEmail,
        commercialId,
        userId,
        userLanguage,
        userName,
        userGroup,
        company,
        companyId,
        userXCompanyList,
        userXProductList,
        allCurrencies,
        isLatamUser: billingCategory && billingCategory === 'LATAM',
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    accessRoles,
    defaultScreen,
    userEmail,
    userId,
    userLanguage,
    userName,
    userGroup,
    company,
    allCurrencies,
    dispatchUserContext,
    userXCompanyList,
    userXProductList,
    billingCategory,
  ])

  React.useEffect(() => {
    if (userTheme) {
      dispatchThemeContext({
        type: 'setUserTheme',
        userTheme,
      })
    }
  }, [userTheme, dispatchThemeContext])

  const fetchSettingsUser = () => {
    if (!requestLoading && !requestError) {
      setRequestError(false)
      setRequestLoading(true)

      const axiosToken = CancelToken.source()
      const cancelToken = axiosToken?.token

      return new Promise((resolve, reject) => {
        const headers = {
          Authorization: `Bearer ${token}`,
        }

        axios
          .get(`${process.env.REACT_APP_API_URL}/settings/user`, {
            headers,
            cancelToken,
          })
          .then(({ data }) => normalizeUserData(data))
          .then((values) => setRequestData(values))
          .catch((err) => {
            setRequestError(true)
            const errMessage = validateError(err)
            reject(errMessage)
          })
          .finally(() => setRequestLoading(false))
      })
    }
  }

  return { requestError, requestLoading, fetchSettingsUser }
}
