import React, { createRef, useEffect, useState } from 'react'
import _ from 'lodash'
import NumberFormat from 'react-number-format'
import {
  FormControl,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextFieldCurrency,
  Typography,
} from '../../../core'
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '../../../icons'
import { intl } from '../../../translation'
import { useStyles } from './styles'

const initialState = {
  activationValue: parseFloat(0).toFixed(7),
}

const initialRow = {
  status: true,
  minLimit: 0,
  maxLimit: '',
  value: parseFloat(0).toFixed(7),
  excessValue: parseFloat(0).toFixed(7),
}
const M2MDetails = ({ changeDetails, currencyData }) => {
  currencyData =
    !currencyData || _.isEmpty(currencyData)
      ? {
          symbol: 'R$ ',
          thousandSeparator: '.',
          decimalSeparator: ',',
        }
      : currencyData

  const classes = useStyles()
  const [state, setState] = useState(initialState)
  const [newRow, setNewRow] = useState({ ...initialRow })
  const [values, setValues] = useState([])
  const [statusForm, setStatusForm] = useState(false)
  const [tariffName, setTariffName] = useState('')

  const inputRef = createRef()

  useEffect(() => {
    if (
      newRow.status === false &&
      state.activationValue !== '' &&
      tariffName !== ''
    ) {
      setStatusForm(true)
    } else {
      setStatusForm(false)
    }
  }, [values, newRow, state, tariffName])

  useEffect(() => {
    if (typeof changeDetails === 'function') {
      if (statusForm) {
        const payload = {
          tariffName,
          details: {
            ...state,
            values,
          },
        }
        changeDetails(payload)
      } else {
        changeDetails({})
      }
    }
  }, [statusForm, tariffName, state])

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus()
  }, [values])

  const handleChangeCurrencyValue = (input, value) => {
    value
      ? setState({ ...state, [input]: parseFloat(value).toFixed(7) })
      : setState({ ...state, [input]: parseFloat(0).toFixed(7) })
  }

  const handleChangeNewRowLimit = (input, value) => {
    value
      ? setNewRow({ ...newRow, [input]: Number(value) })
      : setNewRow({ ...newRow, [input]: '' })
  }

  const handleChangeNewRow = (input, value) => {
    value
      ? setNewRow({ ...newRow, [input]: parseFloat(value).toFixed(7) })
      : setNewRow({ ...newRow, [input]: parseFloat(0).toFixed(7) })
  }

  const handleNewRow = () => {
    const actualNewRow = newRow
    delete actualNewRow.status

    const maxLimit = newRow.maxLimit !== '' ? newRow.maxLimit : -1

    if (
      (actualNewRow.maxLimit === '' ||
        actualNewRow.maxLimit > actualNewRow.minLimit) &&
      actualNewRow.value !== '' &&
      actualNewRow.excessValue !== ''
    ) {
      setValues([
        ...values,
        {
          ...actualNewRow,
          maxLimit,
        },
      ])
      if (actualNewRow.maxLimit !== '') {
        setNewRow({
          ...initialRow,
          minLimit: actualNewRow.maxLimit + 1,
        })
      } else {
        setNewRow({ ...initialRow, status: false })
      }
    }
  }

  const handleDeleteLastRow = () => {
    const newValues = values
    const actualNewRow = values.pop()
    setValues(newValues)
    setNewRow({ ...actualNewRow, maxLimit: '', status: true })
  }

  const handleEditLastRow = () => {
    const newValues = values
    const actualNewRow = values[values.length - 1]
    newValues.pop()
    if (values.length > 1) {
      setValues(newValues)
    }
    setNewRow({ ...actualNewRow, maxLimit: '', status: true })
  }

  return (
    <>
      <Typography
        variant="subtitle1"
        display="block"
        style={{ margin: '0px auto', fontWeight: 'bold' }}
      >
        {intl.get('words.description') || 'words.description'}
      </Typography>
      <div className={classes.bodySecondDetails}>
        <div className={classes.bodySecondDurationName}>
          <FormControl
            variant="outlined"
            className={classes.bodyFirstNameControl}
          >
            <TextField
              size="small"
              variant="outlined"
              margin="dense"
              label={intl.get('words.description') || 'words.description'}
              fullWidth
              InputProps={{
                classes: {
                  root: classes.textFieldTest,
                  input: classes.inputTextfield,
                  notchedOutline: classes.notchedOutline,
                },
                disableUnderline: true,
              }}
              onChange={(e) => setTariffName(e.target.value)}
              value={tariffName}
            />
          </FormControl>
        </div>
        <div className={classes.bodySecondDuration}>
          <FormControl
            variant="outlined"
            className={classes.bodyFirstNameControl}
          >
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              margin="dense"
              label={
                intl.get('phrases.activationValue') || 'phrases.activationValue'
              }
              placeholder="0,0000000"
              InputProps={{
                classes: {
                  root: classes.textFieldTest,
                  input: classes.currency,
                  notchedOutline: classes.notchedOutline,
                },
                disableUnderline: true,
                inputComponent: TextFieldCurrency,
                startAdornment: (
                  <InputAdornment
                    disableTypography
                    style={{ color: 'lightGray' }}
                    position="start"
                  >
                    {currencyData?.symbol}
                  </InputAdornment>
                ),
              }}
              onChange={(e) =>
                handleChangeCurrencyValue('activationValue', e.target.value)
              }
              value={state.activationValue}
            />
          </FormControl>
        </div>
      </div>
      <Typography
        variant="subtitle1"
        display="block"
        style={{ margin: '0px auto', marginTop: 20, fontWeight: 'bold' }}
      >
        {intl.get('phrases.slicesDefinitions') || 'phrases.slicesDefinitions'}
      </Typography>
      <div id="dial-slices" className={classes.bodySecondTable}>
        <Table style={{ width: '90%', margin: '0px auto' }}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">
                {intl.get('words.start') || 'words.start'}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {intl.get('words.end') || 'words.end'}
              </TableCell>
              <TableCell align="right" className={classes.tableCell}>
                {intl.get('words.value') || 'words.value'}
              </TableCell>
              <TableCell align="right" className={classes.tableCell}>
                {intl.get('phrases.exceededValue') || 'phrases.exceededValue'}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {intl.get('words.actions') || 'words.actions'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values.map((row, index) => (
              <TableRow key={row.minLimit} className={classes.tableRow}>
                <TableCell
                  align="center"
                  className={classes.tableCell}
                  component="th"
                  scope="row"
                >
                  {row.minLimit}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {row.maxLimit > 0
                    ? row.maxLimit
                    : intl.get('phrases.noLimit')}
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  <NumberFormat
                    displayType="text"
                    prefix={currencyData?.symbol}
                    thousandSeparator={currencyData?.thousandSeparator}
                    decimalSeparator={currencyData?.decimalSeparator}
                    allowNegative={false}
                    isNumericString
                    decimalScale={7}
                    value={row.value}
                  />
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  <NumberFormat
                    displayType="text"
                    prefix={currencyData?.symbol}
                    thousandSeparator={currencyData?.thousandSeparator}
                    decimalSeparator={currencyData?.decimalSeparator}
                    allowNegative={false}
                    isNumericString
                    decimalScale={7}
                    value={row.excessValue}
                  />
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {index === values.length - 1 && !newRow.status && (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={handleEditLastRow}
                      className={classes.iconButton}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {newRow.status && (
              <TableRow key="newRow" className={classes.tableRow}>
                <TableCell
                  align="center"
                  className={classes.tableCell}
                  component="th"
                  scope="row"
                >
                  {newRow.minLimit}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextField
                    autoFocus
                    inputRef={inputRef}
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    placeholder={
                      intl.get('phrases.noLimit') || 'phrases.noLimit'
                    }
                    InputProps={{
                      classes: {
                        root: classes.textFieldTest,
                        input: classes.inputTextfield,
                        notchedOutline: classes.notchedOutline,
                      },
                      disableUnderline: true,
                    }}
                    onChange={(e) =>
                      handleChangeNewRowLimit('maxLimit', e.target.value)
                    }
                    value={newRow.maxLimit}
                    type="number"
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    placeholder="0,0000000"
                    InputProps={{
                      classes: {
                        root: classes.textFieldTest,
                        input: classes.currency,
                        notchedOutline: classes.notchedOutline,
                      },
                      disableUnderline: true,
                      inputComponent: TextFieldCurrency,
                      startAdornment: (
                        <InputAdornment
                          disableTypography
                          style={{ color: 'lightGray' }}
                          position="start"
                        >
                          {currencyData?.symbol}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      handleChangeNewRow('value', e.target.value)
                    }
                    value={newRow.value}
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    placeholder="0,0000000"
                    InputProps={{
                      classes: {
                        root: classes.textFieldTest,
                        input: classes.currency,
                        notchedOutline: classes.notchedOutline,
                      },
                      disableUnderline: true,
                      inputComponent: TextFieldCurrency,
                      startAdornment: (
                        <InputAdornment
                          disableTypography
                          style={{ color: 'lightGray' }}
                          position="start"
                        >
                          {currencyData?.symbol}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      handleChangeNewRow('excessValue', e.target.value)
                    }
                    value={newRow.excessValue}
                  />
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {(newRow.maxLimit === '' ||
                    newRow.maxLimit > newRow.minLimit) &&
                    newRow.value !== '' &&
                    newRow.excessValue !== '' && (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={handleNewRow}
                        className={classes.iconButton}
                        size="small"
                      >
                        <CheckIcon />
                      </IconButton>
                    )}
                  {values.length > 0 && (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={handleDeleteLastRow}
                      className={classes.iconButton}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
export default M2MDetails
