import React, { createRef, useEffect, useState } from 'react'
import _ from 'lodash'
import NumberFormat from 'react-number-format'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextFieldCurrency,
  Typography,
} from '../../../core'
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '../../../icons'
import { intl } from '../../../translation'
import { useStyles } from './styles'

const initialState = {
  operationsFilter: '',
  inverted: 'false',
  waterfall: true,
}

const initialRow = {
  status: true,
  startRange: 0,
  endRange: '',
  value: parseFloat(0).toFixed(7),
}
const IPDetails = ({ changeDetails, currencyData }) => {
  currencyData =
    !currencyData || _.isEmpty(currencyData)
      ? {
          symbol: 'R$ ',
          thousandSeparator: '.',
          decimalSeparator: ',',
        }
      : currencyData

  const classes = useStyles()
  const [state, setState] = useState(initialState)
  const [newRow, setNewRow] = useState({ ...initialRow })
  const [values, setValues] = useState([])
  const [statusForm, setStatusForm] = useState(false)
  const [tariffName, setTariffName] = useState('')

  const inputRef = createRef()

  useEffect(() => {
    if (newRow.status === false && tariffName !== '') {
      setStatusForm(true)
    } else {
      setStatusForm(false)
    }
  }, [values, newRow, state, tariffName])

  useEffect(() => {
    if (typeof changeDetails === 'function') {
      if (statusForm) {
        const statusOperations = !state?.operationsFilter?.length && false
        const payload = {
          tariffName,
          operationsFilter: statusOperations
            ? state.operationsFilter.split(',')
            : null,
          inverted: statusOperations,
          details: {
            ...state,
            values,
          },
        }
        changeDetails(payload)
      } else {
        changeDetails({})
      }
    }
  }, [statusForm, tariffName, state])

  useEffect(() => {
    // eslint-disable-next-line no-unused-expressions
    inputRef && inputRef.current && inputRef.current.focus()
  }, [values])

  const handleChangeValue = (input, value) => {
    value
      ? setState({ ...state, [input]: value })
      : setState({ ...state, [input]: '' })
  }

  const handleChangeDuration = (input, value) => {
    value && value >= 0
      ? setState({ ...state, [input]: Number(value) })
      : setState({ ...state, [input]: '' })
  }

  const handleChangeCumulative = () => {
    setState({ ...state, waterfall: !state.waterfall })
  }

  const handleChangeNewRowLimit = (input, value) => {
    value
      ? setNewRow({ ...newRow, [input]: Number(value) })
      : setNewRow({ ...newRow, [input]: '' })
  }

  const handleChangeNewRow = (input, value) => {
    value
      ? setNewRow({ ...newRow, [input]: parseFloat(value).toFixed(7) })
      : setNewRow({ ...newRow, [input]: parseFloat(0).toFixed(7) })
  }

  const handleNewRow = () => {
    const actualNewRow = newRow
    delete actualNewRow.status

    const endRange = newRow.endRange !== '' ? newRow.endRange : -1

    if (
      (actualNewRow.endRange === '' ||
        actualNewRow.endRange > actualNewRow.startRange) &&
      actualNewRow.value !== ''
    ) {
      setValues([
        ...values,
        {
          ...actualNewRow,
          endRange,
        },
      ])
      if (actualNewRow.endRange !== '') {
        setNewRow({
          ...initialRow,
          startRange: actualNewRow.endRange + 1,
          status: true,
        })
      } else {
        setNewRow({ ...initialRow, status: false })
      }
    }
  }

  const handleDeleteLastRow = () => {
    const newValues = values
    const actualNewRow = values.pop()
    setValues(newValues)
    setNewRow({ ...actualNewRow, endRange: '', status: true })
  }

  const handleEditLastRow = () => {
    const newValues = values
    const actualNewRow = values[values.length - 1]
    newValues.pop()
    if (values.length > 1) {
      setValues(newValues)
    }
    setNewRow({ ...actualNewRow, endRange: '', status: true })
  }

  const handleChangeRadio = (event) => {
    setState({ ...state, inverted: event.target.value })
    setStatusForm(false)
  }

  return (
    <>
      <Typography
        variant="subtitle1"
        display="block"
        style={{ margin: '0px auto', fontWeight: 'bold' }}
      >
        {intl.get('words.description') || 'words.description'}
      </Typography>
      <div className={classes.bodySecondDetails}>
        <div className={classes.bodySecondDurationName}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              size="small"
              variant="outlined"
              margin="dense"
              label={intl.get('phrases.tariffName') || 'phrases.tariffName'}
              fullWidth
              InputProps={{
                classes: {
                  root: classes.textFieldTest,
                  input: classes.inputTextfield,
                  notchedOutline: classes.notchedOutline,
                },
                disableUnderline: true,
              }}
              onChange={(e) => setTariffName(e.target.value)}
              value={tariffName}
            />
          </FormControl>
        </div>
        <div className={classes.bodySecondDuration}>
          <FormControl variant="outlined" className={classes.formControl}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              margin="dense"
              label={
                intl.get(`phrases.initialDuration`) || 'phrases.initialDuration'
              }
              InputProps={{
                classes: {
                  root: classes.textFieldTest,
                  input: classes.inputTextfield,
                  notchedOutline: classes.notchedOutline,
                },
                disableUnderline: true,
              }}
              onChange={(e) =>
                handleChangeDuration('initial_duration', e.target.value)
              }
              value={state.initial_duration}
              type="number"
            />
          </FormControl>
        </div>
      </div>
      <div className={classes.bodySecondDetails}>
        <div className={classes.bodySecondDurationName}>
          <FormControl className={classes.formControl}>
            <TextField
              fullWidth
              size="small"
              variant="outlined"
              margin="dense"
              label={
                intl.get(`phrases.operationsFilter`) ||
                'phrases.operationsFilter'
              }
              InputProps={{
                classes: {
                  root: classes.textFieldTest,
                  input: classes.inputTextfield,
                  notchedOutline: classes.notchedOutline,
                },
                disableUnderline: true,
              }}
              onChange={(e) =>
                handleChangeValue('operationsFilter', e.target.value)
              }
              value={state.operationsFilter}
            />
          </FormControl>
        </div>
        <div className={classes.bodySecondDuration}>
          <FormControl className={classes.radios}>
            <FormLabel component="legend" style={{ fontSize: '0.8em' }}>
              {intl.get('words.actions') || 'words.actions'}
            </FormLabel>
            <RadioGroup
              aria-label="position"
              name="position"
              value={state.inverted}
              onChange={handleChangeRadio}
              row
              style={{
                flexDirection: 'row',
                display: 'flex',
                flexWrap: 'nowrap',
              }}
            >
              <FormControlLabel
                disabled={state.operationsFilter.length === 0}
                value="false"
                control={<Radio color="primary" />}
                label={intl.get(`words.include`) || 'words.include'}
                labelPlacement={intl.get(`words.include`) || 'words.include'}
              />
              <FormControlLabel
                disabled={state.operationsFilter.length === 0}
                value="true"
                control={<Radio color="primary" />}
                label={intl.get(`words.exclude`) || 'words.exclude'}
                labelPlacement={intl.get(`words.exclude`) || 'words.exclude'}
              />
            </RadioGroup>
          </FormControl>
        </div>
        <div className={classes.bodySecondDuration}>
          <FormControl className={classes.checkbox}>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  classes={{
                    checked: classes.checked,
                  }}
                  checked={state.waterfall}
                  onChange={handleChangeCumulative}
                />
              }
              label={intl.get('phrases.cumulative') || 'phrases.cumulative'}
            />
          </FormControl>
        </div>
      </div>
      <Typography
        variant="subtitle1"
        display="block"
        style={{ margin: '0px auto', fontWeight: 'bold' }}
      >
        {intl.get('phrases.slicesDefinitions') || 'phrases.slicesDefinitions'}
      </Typography>
      <div id="dial-slices" className={classes.bodySecondTable}>
        <Table style={{ width: '100%', margin: '0px auto' }}>
          <TableHead>
            <TableRow className={classes.tableRow}>
              <TableCell align="center">
                {intl.get('words.start') || 'words.start'}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {intl.get('words.end') || 'words.end'}
              </TableCell>
              <TableCell align="right" className={classes.tableCell}>
                {intl.get('words.value') || 'words.value'}
              </TableCell>
              <TableCell align="center" className={classes.tableCell}>
                {intl.get('words.actions') || 'words.actions'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {values?.map((row, index) => (
              <TableRow key={row.minLimit} className={classes.tableRow}>
                <TableCell
                  align="center"
                  className={classes.tableCell}
                  component="th"
                  scope="row"
                >
                  {row.startRange}
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {row.endRange > 0
                    ? row.endRange
                    : intl.get('phrases.noLimit')}
                </TableCell>
                <TableCell align="right" className={classes.tableCell}>
                  <NumberFormat
                    displayType="text"
                    prefix={currencyData?.symbol}
                    thousandSeparator={currencyData?.thousandSeparator}
                    decimalSeparator={currencyData?.decimalSeparator}
                    allowNegative={false}
                    isNumericString
                    decimalScale={7}
                    value={row.value}
                  />
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {index === values.length - 1 && !newRow.status && (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={handleEditLastRow}
                      className={classes.iconButton}
                      size="small"
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            ))}
            {newRow.status && (
              <TableRow key="newRow" className={classes.tableRow}>
                <TableCell
                  align="center"
                  className={classes.tableCell}
                  component="th"
                  scope="row"
                >
                  {newRow.startRange}
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextField
                    autoFocus
                    inputRef={inputRef}
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    placeholder={
                      intl.get('phrases.noLimit') || 'phrases.noLimit'
                    }
                    InputProps={{
                      classes: {
                        root: classes.textFieldTest,
                        input: classes.inputTextfield,
                        notchedOutline: classes.notchedOutline,
                      },
                      disableUnderline: true,
                    }}
                    onChange={(e) =>
                      handleChangeNewRowLimit('endRange', e.target.value)
                    }
                    value={newRow.endRange}
                    type="number"
                  />
                </TableCell>
                <TableCell className={classes.tableCell}>
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    placeholder="0,0000000"
                    InputProps={{
                      classes: {
                        root: classes.textFieldTest,
                        input: classes.currency,
                        notchedOutline: classes.notchedOutline,
                      },
                      disableUnderline: true,
                      inputComponent: TextFieldCurrency,
                      startAdornment: (
                        <InputAdornment
                          disableTypography
                          style={{ color: 'lightGray' }}
                          position="start"
                        >
                          {currencyData?.symbol}
                        </InputAdornment>
                      ),
                    }}
                    onChange={(e) =>
                      handleChangeNewRow('value', e.target.value)
                    }
                    value={newRow.value}
                  />
                </TableCell>
                <TableCell align="center" className={classes.tableCell}>
                  {(newRow.endRange === '' ||
                    newRow.endRange > newRow.startRange) &&
                    newRow.value !== '' && (
                      <IconButton
                        color="primary"
                        aria-label="upload picture"
                        component="span"
                        onClick={handleNewRow}
                        className={classes.iconButton}
                        size="small"
                      >
                        <CheckIcon />
                      </IconButton>
                    )}
                  {values.length > 0 && (
                    <IconButton
                      color="primary"
                      aria-label="upload picture"
                      component="span"
                      onClick={handleDeleteLastRow}
                      className={classes.iconButton}
                      size="small"
                    >
                      <DeleteIcon />
                    </IconButton>
                  )}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </>
  )
}
export default IPDetails
