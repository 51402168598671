import { useFetchAxiosWrapper } from '../useFetchAxiosWrapper'
import ENDPOINTS from './ENDPOINTS'
import {
  normalizeTariffCreation,
  normalizeTariffData,
  normalizeTariffUpdate,
} from './Normalizers'
import { useState, useEffect } from 'react'
import _ from 'lodash'

const filterQuery = (values) => {
  return {
    groupBy: values?.groupBy,
    order: values?.order,
    orderBy: values?.orderBy,
    productDetails: values?.productDetails,
    detailed: values?.detailed,
    cycleStart: values?.cycleStart,
    cycleEnd: values?.cycleEnd,
    productId: values?.productId,
    customerId: values?.customerId,
    categoryId: values?.categoryId,
    commercialId: values?.commercialId,
    cards: values?.cards,
    paymentMonth: values?.paymentMonth,
    year: values?.year,
    componentDetails: values?.componentDetails,
    limit: values?.limit,
    page: values?.page,
  }
}

export const useLibraryTariff = (params) => {
  const [data, setData] = useState([])
  const { callOnInit = true } = params || {}
  const {
    reset,
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    fetchGET,
    fetchPATCH,
    fetchPOST,
    fetchPUT,
  } = useFetchAxiosWrapper({
    baseEndpoint: ENDPOINTS.GET.tariff_results,
    callOnInit,
  })

  useEffect(() => {
    if (!_.isEqual(getRequest.data, data)) {
      setData(getRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getRequest])

  useEffect(() => {
    if (!_.isEqual(patchRequest.data, data)) {
      setData(patchRequest.data)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [patchRequest])

  const handleGET = ({ urlParameters }) => {
    const newQuery = filterQuery(urlParameters)
    fetchGET({
      urlParameters: { ...newQuery },
    })
  }

  const handlePATCH = (params = {}) => {
    fetchPATCH({
      body: { ...params },
    })
  }

  const handlePOST = (data = {}) => {
    const newData = normalizeTariffCreation(data)
    fetchPOST({
      body: newData,
      noRefreshData: true,
    })
  }

  const handlePUT = (data = {}) => {
    const newData = normalizeTariffUpdate(data)
    fetchPUT({
      body: newData,
      noRefreshData: true,
    })
  }

  const handlePOST_LATAM = (data = {}) => {
    fetchPOST({
      body: data,
      noRefreshData: true,
    })
  }

  const handlePUT_LATAM = (data = {}) => {
    fetchPUT({
      body: data,
      noRefreshData: true,
    })
  }

  const handlePOSTAssociate = (data = {}) => {
    fetchPOST({
      forceEndpoint: ENDPOINTS.POST.tariff_association,
      body: data,
      noRefreshData: true,
    })
  }

  const handlePOSTAssociate_LATAM = (data = {}) => {
    fetchPOST({
      forceEndpoint: ENDPOINTS.POST.tariff_association,
      body: data,
      noRefreshData: true,
    })
  }

  const handlePUTAssociate_LATAM = (data = {}) => {
    fetchPUT({
      forceEndpoint: ENDPOINTS.POST.tariff_association,
      body: data,
      noRefreshData: true,
    })
  }

  const handleDELETEAssociate_LATAM = (data = {}) => {
    fetchPUT({
      forceEndpoint: ENDPOINTS.PUT.tariff_association_delete,
      body: data,
      noRefreshData: true,
    })
  }

  return {
    data: normalizeTariffData({ data: data }),
    getRequest,
    postRequest,
    putRequest,
    patchRequest,
    deleteRequest,
    reset,
    handleGET,
    handlePATCH,
    handlePOST,
    handlePOSTAssociate,
    handlePUT,
    handlePOST_LATAM,
    handlePUT_LATAM,
    handlePOSTAssociate_LATAM,
    handlePUTAssociate_LATAM,
    handleDELETEAssociate_LATAM,
  }
}
