import { makeStyles } from '../../styles'

export const useStyles = makeStyles((theme) => ({
  listHeader: {
    minHeight: 60,
    display: 'flex',
    padding: '5px 15px',
    alignItems: 'center',
    backgroundColor: theme?.palette?.primary?.main,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    flexWrap: 'wrap',
  },
  buttonsLeft: {
    textAlign: 'left',
    flex: 2,
  },
  buttonsRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    textAlign: 'right',
    flex: 2,
  },
  buttonsCenter: {
    textAlign: 'center',
    flex: 1,
  },
}))
