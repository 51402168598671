import { makeStyles } from '../../../styles'

export const useStyles = makeStyles((theme) => ({
  containerBar: {
    width: '100%',
    border: '1px solid lightGray',
    borderRadius: 5,
  },
  containerDonut: {
    width: '50%',
    border: '1px solid lightGray',
    paddingTop: 0,
    borderRadius: 5,
    marginTop: 3,
    marginBottom: 3,
    display: 'inline-block',
  },
  containerTable: {
    width: '50%',
    paddingTop: 0,
    borderRadius: 5,
    marginTop: 3,
    marginBottom: 3,
    display: 'inline-block',
  },
  chart: {
    fontFamily: theme.typography.fontFamily,
  },
  label: {
    width: '95%',
    textAlign: 'right',
    paddingTop: 10,
    display: 'block',
    fontFamily: "'Titillium Web', sans-serif",
    color: 'gray',
    fontSize: '0.9em',
    textTransform: 'uppercase',
  },
  header: {
    position: 'sticky',
    top: 0,
    height: '10%',
    borderBottom: '1px solid lightGray',
    zIndex: 99999,
  },
  headerDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
  },
  headerSelect: {
    display: 'flex',
    width: '70%',
    justifyContent: 'flex-start',
    paddingLeft: 30,
  },
  headerButton: {
    display: 'flex',
    width: '30%',
    justifyContent: 'flex-end',
    paddingTop: 0,
  },
  bodyFirst: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignItems: 'stretch',
    height: '30%',
  },
  bodyFirstName: {
    display: 'flex',
    width: '40%',
    justifyContent: 'flex-start',
    paddingLeft: 30,
  },
  bodyFirstNameControl: {
    minWidth: '100%',
  },
  bodyFirstNameTextfield: {
    fontSize: '0.9em',
    fontFamily: theme.typography.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 0,
    border: `1px solid ${theme?.palettePers?.text.tertiary}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palettePers?.text.tertiary,
    marginBottom: 5,
    marginTop: 10,
    width: '100%',
  },
  textFieldTest: {
    fontFamily: theme.typography.fontFamily,
    width: '100%',
  },
  inputTextfield: {
    color: theme?.palettePers?.text.tertiary,
  },
  notchedOutline: {
    borderColor: theme?.palettePers?.text.tertiary,
  },
  focused: {
    borderColor: theme?.palettePers?.text.tertiary,
  },
  bodyFirstNameTextfieldCurrency: {
    fontSize: '0.9em',
    fontFamily: theme.typography.fontFamily,
    height: 40,
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    border: `1px solid ${theme?.palettePers?.text.tertiary}`,
    overflow: 'hidden',
    borderRadius: 4,
    color: theme?.palettePers?.text.tertiary,
    marginBottom: 5,
    marginTop: 5,
    width: '100%',
    textAlign: 'right',
  },
  currency: {
    color: theme?.palettePers?.text.tertiary,
    textAlign: 'right',
  },
  bodyFirstType: {
    display: 'flex',
    width: '25%',
    justifyContent: 'flex-start',
    paddingLeft: 30,
  },
  bodyFirstOperator: {
    display: 'flex',
    width: '25%',
    justifyContent: 'flex-start',
    paddingLeft: 30,
  },
  bodyFirstCumulative: {
    display: 'flex',
    width: '10%',
    justifyContent: 'flex-start',
  },
  bodySecond: {
    minHeight: '60%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'stretch',
  },
  bodySecondDetails: {
    display: 'flex',
    justifyContent: 'flex-start',
    width: '100%',
    padding: '5px 10px',
    margin: 0,
  },
  bodySecondDuration: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    maxWidth: 350,
    padding: '0px 10px',
  },
  bodySecondDurationName: {
    display: 'flex',
    justifyContent: 'center',
    minWidth: 500,
    width: '100%',
    padding: '0px 10px',
  },
  bodySecondTable: {
    width: '100%',
    minHeight: '200px',
    overflow: 'auto',
  },
  margin: {
    marginTop: 20,
  },
  select: {
    height: 40,
    width: 250,
    paddingTop: 0,
    paddingBottom: 0,
    marginBottom: 5,
    marginTop: 5,
    backgroundColor: theme.palette.primary,
  },
  textfield: {
    height: 40,
    width: '100%',
  },
  monthSelect: {
    minWidth: 200,
  },
  iconButton: {
    margin: 10,
  },
  checkbox: {
    display: 'inline-block',
    color: theme?.palettePers?.text.tertiary,
  },
  labelText: {
    display: 'inline-block',
    padding: 0,
    marginTop: 10,
  },
  tableRow: {
    height: 50,
  },
  tableCell: {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 10,
    paddingRight: 10,
    width: '110px',
  },
  tableAddControl: {
    width: '100%',
  },
  tableAddControlNumber: {
    textAlign: 'right',
    width: '100%',
  },
  number: {
    textAlign: 'center',
  },
  inputTeste: {
    color: 'red',
  },
  formControl: {
    width: '100%',
  },
}))
