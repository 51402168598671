import moment from 'moment'
import _ from 'lodash'

// Processa e retorna os valores do filtro e define a query
export const processFilters = (filterPossibilities, state) => {
  const filtersSelected = []
  const momentState = state
  Object.keys(momentState).map((item) => {
    if (filterPossibilities?.findIndex((x) => x.defaultName === item) >= 0) {
      return filtersSelected.push(verifyValue(state, item))
    }
    return null
  })

  let filtersCleaned = []
  if (filtersSelected) {
    filtersCleaned = filtersSelected.filter((item) => {
      return (
        (!Array.isArray(item) && item !== null && item !== undefined) ||
        (Array.isArray(item) && item.length)
      )
    })
  }

  const filtersReady = {}
  if (filtersCleaned) {
    filtersCleaned.map((item) => {
      if (Array.isArray(item) && item?.length) {
        item.map((sub) => {
          filtersReady[sub?.defaultColumn] = sub?.value
          return filtersReady[sub?.defaultColumn]
        })
      } else {
        filtersReady[item?.defaultColumn] = item?.value
        return filtersReady[item?.defaultColumn]
      }
      return null
    })
  }

  return filtersReady
}

// Verifica as informações e aplica o nome das propriedades
export const verifyValue = (state, item) => {
  const arrayValues = []

  if (state?.[item]?.type) {
    switch (state[item]?.type) {
      case 'date':
        if (
          state[item]?.timeStart !== null &&
          state[item]?.timeStart !== undefined &&
          state[item]?.timeStart !== '' &&
          moment(state[item]?.timeStart, 'HH:mm:ss', true).isValid() &&
          state[item]?.dateStart !== null &&
          state[item]?.dateStart !== undefined &&
          state[item]?.dateStart !== '' &&
          moment(state[item]?.dateStart).isValid()
        ) {
          const { dateStart, timeStart } = state[item]

          arrayValues.push({
            defaultColumn: `${item}Start`,
            value: `${moment(dateStart).format('YYYY-MM-DD')} ${timeStart}`,
          })
        }
        if (
          state[item]?.timeEnd !== null &&
          state[item]?.timeEnd !== undefined &&
          state[item]?.timeEnd !== '' &&
          moment(state[item]?.timeEnd, 'HH:mm:ss', true).isValid() &&
          state[item]?.dateEnd !== null &&
          state[item]?.dateEnd !== undefined &&
          state[item]?.dateEnd !== '' &&
          moment(state[item]?.dateEnd).isValid()
        ) {
          const { dateEnd, timeEnd } = state[item]

          arrayValues.push({
            defaultColumn: `${item}End`,
            value: `${moment(dateEnd).format('YYYY-MM-DD')} ${timeEnd}`,
          })
        }
        if (arrayValues.length > 0) {
          return arrayValues
        }
        return null

      case 'monthPeriod':
        if (
          state[item]?.date !== null &&
          state[item]?.date !== undefined &&
          state[item]?.date !== ''
        ) {
          arrayValues.push({
            defaultColumn: `${item}`,
            value: moment(state[item]?.date).format('YYYYMM'),
          })
        }

        if (
          state[item]?.dateStart !== null &&
          state[item]?.dateStart !== undefined &&
          state[item]?.dateStart !== ''
        ) {
          arrayValues.push({
            defaultColumn: `${item}Start`,
            value: moment(state[item]?.dateStart)
              .startOf('month')
              .format('YYYYMM'),
          })
        }

        if (
          state[item]?.dateEnd !== null &&
          state[item]?.dateEnd !== undefined &&
          state[item]?.dateEnd !== ''
        ) {
          arrayValues.push({
            defaultColumn: `${item}End`,
            value: moment(state[item]?.dateEnd).endOf('month').format('YYYYMM'),
          })
        }
        if (
          state[item]?.periodType !== null &&
          state[item]?.periodType !== undefined &&
          state[item]?.periodType !== ''
        ) {
          arrayValues.push({
            defaultColumn: `${item}Type`,
            value: state[item]?.periodType,
          })
        }
        if (arrayValues.length > 0) {
          return arrayValues
        }
        return null
      case 'month':
        if (
          state[item]?.date !== null &&
          state[item]?.date !== undefined &&
          state[item]?.date !== '' &&
          moment(state[item]?.date).isValid()
        ) {
          arrayValues.push({
            defaultColumn: `${item}`,
            value: moment(state[item]?.date).startOf('month').format('YYYYMM'),
          })
        }
        if (arrayValues.length > 0) {
          return arrayValues
        }
        return null

      case 'shortDatePeriod':
        if (
          state[item]?.date !== null &&
          state[item]?.date !== undefined &&
          state[item]?.date !== '' &&
          moment(state[item]?.date).isValid()
        ) {
          arrayValues.push({
            defaultColumn: `${item}`,
            value: moment(state[item]?.date).format('YYYYMM'),
          })
        }

        if (
          state[item]?.dateStart !== null &&
          state[item]?.dateStart !== undefined &&
          state[item]?.dateStart !== '' &&
          moment(state[item]?.dateStart).isValid()
        ) {
          arrayValues.push({
            defaultColumn: `${item}Start`,
            value: moment(state[item]?.dateStart).format('YYYY-MM-DD'),
          })
        }

        if (
          state[item]?.dateEnd !== null &&
          state[item]?.dateEnd !== undefined &&
          state[item]?.dateEnd !== '' &&
          moment(state[item]?.dateEnd).isValid()
        ) {
          arrayValues.push({
            defaultColumn: `${item}End`,
            value: moment(state[item]?.dateEnd).format('YYYY-MM-DD'),
          })
        }

        if (
          state[item]?.periodType !== null &&
          state[item]?.periodType !== undefined &&
          state[item]?.periodType !== ''
        ) {
          arrayValues.push({
            defaultColumn: `${item}Type`,
            value: state[item]?.periodType,
          })
        }
        if (arrayValues.length > 0) {
          return arrayValues
        }
        return null
      case 'shortDate':
        if (
          state[item]?.date !== null &&
          state[item]?.date !== undefined &&
          state[item]?.date !== '' &&
          moment(state[item]?.date).isValid()
        ) {
          arrayValues.push({
            defaultColumn: `${item}Start`,
            value: moment(state[item]?.date).format('YYYY-MM-DD'),
          })
        }
        if (arrayValues.length > 0) {
          return arrayValues
        }
        return null
      case 'fullYear':
        if (
          state[item]?.date !== null &&
          state[item]?.date !== undefined &&
          state[item]?.date !== '' &&
          moment(state[item]?.date).isValid()
        ) {
          arrayValues.push({
            defaultColumn: `${item}`,
            value: moment(state[item]?.date).format('YYYY'),
          })
        }
        if (arrayValues.length > 0) {
          return arrayValues
        }
        return null

      case 'range':
        if (state[item]?.behavior === 'bytes') {
          if (
            state[item]?.valueMin !== null &&
            state[item]?.valueMin !== undefined &&
            state[item]?.valueMin !== ''
          ) {
            arrayValues.push({
              defaultColumn: `${item}Start`,
              value: convertBytes(state, item, state[item]?.valueMin),
            })
          }
          if (
            state[item]?.valueMax !== null &&
            state[item]?.valueMax !== undefined &&
            state[item]?.valueMax !== ''
          ) {
            arrayValues.push({
              defaultColumn: `${item}End`,
              value: convertBytes(state, item, state[item]?.valueMax),
            })
          }

          return arrayValues
        }
        if (state[item]?.behavior === 'number') {
          if (
            state[item]?.valueMin !== null &&
            state[item]?.valueMin !== undefined &&
            state[item]?.valueMin !== ''
          ) {
            arrayValues.push({
              defaultColumn: `${item}Start`,
              value: state[item]?.valueMin,
            })
          }
          if (
            state[item]?.valueMax !== null &&
            state[item]?.valueMax !== undefined &&
            state[item]?.valueMax !== ''
          ) {
            arrayValues.push({
              defaultColumn: `${item}End`,
              value: state[item]?.valueMax,
            })
          }
          return arrayValues
        }
        if (state[item]?.behavior === 'percentage') {
          if (
            state[item]?.valueMin !== null &&
            state[item]?.valueMin !== undefined &&
            state[item]?.valueMin !== ''
          ) {
            arrayValues.push({
              defaultColumn: `${item}Start`,
              value: state[item]?.valueMin,
            })
          }
          if (
            state[item]?.valueMax !== null &&
            state[item]?.valueMax !== undefined &&
            state[item]?.valueMax !== ''
          ) {
            arrayValues.push({
              defaultColumn: `${item}End`,
              value: state[item]?.valueMax,
            })
          }
          return arrayValues
        }
        if (state[item]?.behavior === 'currency') {
          if (
            state[item]?.valueMin !== null &&
            state[item]?.valueMin !== undefined &&
            state[item]?.valueMin !== '' &&
            state[item]?.valueMin !== 0
          ) {
            arrayValues.push({
              defaultColumn: `${item}Start`,
              value: state[item]?.valueMin,
            })
          }
          if (
            state[item]?.valueMax !== null &&
            state[item]?.valueMax !== undefined &&
            state[item]?.valueMax !== '' &&
            state[item]?.valueMax !== 0
          ) {
            arrayValues.push({
              defaultColumn: `${item}End`,
              value: state[item]?.valueMax,
            })
          }
          return arrayValues
        }
        break
      case 'select':
        if (
          state[item]?.value !== null &&
          state[item]?.value !== undefined &&
          state[item]?.value !== '' &&
          !_.isEmpty(state[item]?.value)
        ) {
          return {
            defaultColumn: !state[item]?.condition
              ? `${item}NotEqual`
              : `${item}`,
            value: state[item]?.value.value,
          }
        }
        return null

      case 'text':
        if (
          state[item]?.value !== null &&
          state[item]?.value !== undefined &&
          state[item]?.value !== ''
        ) {
          if (!state[item]?.isSuggested) {
            return {
              defaultColumn: state[item]?.condition
                ? state[item].condition
                  ? `${item}Start`
                  : `${item}StartNotEqual`
                : `${item}Start`,
              value: state[item]?.value,
            }
          }
          return {
            defaultColumn: state[item]?.condition
              ? state[item].condition
                ? `${item}`
                : `${item}NotEqual`
              : `${item}`,
            value: state[item]?.value,
          }
        }
        return null
      case 'number':
        if (
          state[item]?.value !== null &&
          state[item]?.value !== undefined &&
          state[item]?.value !== ''
        ) {
          if (!state[item]?.isSuggested) {
            return {
              defaultColumn: state[item]?.condition
                ? state[item].condition
                  ? `${item}Start`
                  : `${item}StartNotEqual`
                : `${item}Start`,
              value: state[item]?.value,
            }
          }
          return {
            defaultColumn: state[item]?.condition
              ? state[item].condition
                ? `${item}`
                : `${item}NotEqual`
              : `${item}`,
            value: state[item]?.value,
          }
        }
        return null
      case 'multi-select':
        if (
          state[item]?.value !== null &&
          state[item]?.value !== undefined &&
          Array.isArray(state[item]?.value) &&
          state[item]?.value.length > 0
        ) {
          const newValues = state[item]?.value.map((item) => {
            return item.value
          })
          return {
            defaultColumn: !state[item]?.condition
              ? `${item}ListNotEqual`
              : `${item}List`,
            value: newValues,
          }
        }
        return null
      case 'multi-creatable-select':
        if (
          state[item]?.value !== null &&
          state[item]?.value !== undefined &&
          Array.isArray(state[item]?.value) &&
          state[item]?.value.length > 0
        ) {
          const newValues = state[item]?.value.map((item) => {
            return item.value
          })
          return {
            defaultColumn: !state[item]?.condition
              ? `${item}ListNotEqual`
              : `${item}List`,
            value: newValues,
          }
        }
        return null

      case 'id':
        if (
          state[item]?.value !== null &&
          state[item]?.value !== undefined &&
          state[item]?.value !== ''
        ) {
          if (!state[item]?.isSuggested) {
            return {
              defaultColumn: state[item]?.condition
                ? state[item].condition
                  ? `${item}Start`
                  : `${item}StartNotEqual`
                : `${item}Start`,
              value: state[item]?.id,
            }
          }
          return {
            defaultColumn: state[item]?.condition
              ? state[item].condition
                ? `${item}`
                : `${item}NotEqual`
              : `${item}`,
            value: state[item]?.id,
          }
        }
        return null

      case 'radio':
        if (
          state[item]?.value !== null &&
          state[item]?.value !== undefined &&
          state[item]?.value !== ''
        ) {
          return {
            defaultColumn: state[item]?.defaultName,
            value: state[item]?.value,
          }
        }
        return null

      case 'checkbox':
        if (
          state[item]?.value !== null &&
          state[item]?.value !== undefined &&
          state[item]?.value !== false
        ) {
          return {
            defaultColumn: state[item]?.defaultName,
            value: state[item]?.value,
          }
        }
        return null
      default:
        return null
    }
  }
}

// Converte as informações do select de bytes
const convertBytes = (state, item, value) => {
  switch (state[item]?.density) {
    case 'B':
      return value * 1
    case 'KB':
      return value * 1024
    case 'MB':
      return value * 1048576
    default:
      return value
  }
}
