/* eslint-disable no-unused-expressions */
/* eslint-disable react/prop-types */
import React, { createRef, useEffect, useState } from 'react'
import _ from 'lodash'
import NumberFormat from 'react-number-format'
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  TextFieldCurrency,
  Typography,
} from '../../../core'
import {
  Check as CheckIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from '../../../icons'
import { intl } from '../../../translation'
import { useStyles } from './styles'

const initialState = {
  waterfall: true,
  waterfallStore: true,
  minimumTransaction: 0,
  minimumValue: parseFloat(0).toFixed(7),
  minimumValueByStore: parseFloat(0).toFixed(7),
  countByTransactedStores: false,
  minimumTransactionByStore: 0,
  storeSetup: parseFloat(0).toFixed(7),
}

const initialRow = {
  status: true,
  minLimit: 1,
  maxLimit: '',
  value: parseFloat(0).toFixed(7),
}
const PayzenDetails = ({ changeDetails, billingPayzenType, currencyData }) => {
  currencyData =
    !currencyData || _.isEmpty(currencyData)
      ? {
          symbol: 'R$ ',
          thousandSeparator: '.',
          decimalSeparator: ',',
        }
      : currencyData

  const classes = useStyles()
  const [statePayzen, setState] = useState(initialState)
  const [newRow, setNewRow] = useState({ ...initialRow })
  const [newRowByStore, setNewRowByStore] = useState(initialRow)
  const [values, setValues] = useState([])
  const [valuesByStore, setValuesByStore] = useState([])
  const [tab, setTab] = useState(0)
  const [statusForm, setStatusForm] = useState(false)
  const [tariffName, setTariffName] = useState('')

  const [minimumTransactionNoLimit, setMinimumTransactionNoLimit] =
    useState(false)
  const [
    minimumTransactionByStoreNoLimit,
    setMinimumTransactionByStoreNoLimit,
  ] = useState(false)

  const inputRef = createRef()

  useEffect(() => {
    const status = validateFormByBillingPayzenTypeRules()
    statusForm !== status && setStatusForm(status)
  }, [
    values,
    newRow,
    tariffName,
    valuesByStore,
    newRowByStore,
    billingPayzenType,
    statePayzen,
  ])

  useEffect(() => {
    if (typeof changeDetails === 'function') {
      if (statusForm) {
        const payload = createObjectByBillingPayzenTypeRules()

        changeDetails(payload)
      } else {
        changeDetails({})
      }
    }
  }, [statusForm])

  useEffect(() => {
    inputRef && inputRef.current && inputRef.current.focus()
  }, [values])

  const createObjectByBillingPayzenTypeRules = () => {
    switch (billingPayzenType) {
      case 0:
        return {
          tariffName,
          details: {
            billingType: billingPayzenType,
            minimumTransaction: minimumTransactionNoLimit
              ? -1
              : statePayzen.minimumTransaction,
            minimumValue: statePayzen.minimumValue,
            waterfall: statePayzen.waterfall,
            values,
          },
        }
      case 1:
        return {
          tariffName,
          details: {
            billingType: billingPayzenType,
            minimumTransaction: minimumTransactionNoLimit
              ? -1
              : statePayzen.minimumTransaction,
            minimumValue: statePayzen.minimumValue,
            minimumValueByStore: statePayzen.minimumValueByStore,
            waterfall: statePayzen.waterfall,
            values,
          },
        }
      case 2:
        return {
          tariffName,
          details: {
            billingType: billingPayzenType,
            minimumTransaction: minimumTransactionNoLimit
              ? -1
              : statePayzen.minimumTransaction,
            minimumValue: statePayzen.minimumValue,
            waterfall: statePayzen.waterfall,
            waterfallStore: statePayzen.waterfallStore,
            values,
            valuesByStore,
            countByTransactedStores: statePayzen.countByTransactedStores,
          },
        }
      case 3:
        return {
          tariffName,
          details: {
            billingType: billingPayzenType,
            minimumTransaction: minimumTransactionNoLimit
              ? -1
              : statePayzen.minimumTransaction,
            minimumValue: statePayzen.minimumValue,
            waterfall: statePayzen.waterfall,
            waterfallStore: statePayzen.waterfallStore,
            values,
            valuesByStore,
            countByTransactedStores: statePayzen.countByTransactedStores,
          },
        }
      case 4:
        return {
          tariffName,
          details: {
            billingType: billingPayzenType,
            minimumTransaction: minimumTransactionNoLimit
              ? -1
              : statePayzen.minimumTransaction,
            minimumValue: statePayzen.minimumValue,
            waterfall: statePayzen.waterfall,
            values,
          },
        }
      case 5:
        return {
          tariffName,
          details: {
            billingType: billingPayzenType,
            minimumTransaction: minimumTransactionNoLimit
              ? -1
              : statePayzen.minimumTransaction,
            minimumValue: statePayzen.minimumValue,
            waterfall: statePayzen.waterfall,
            values,
          },
        }
      case 6:
        return {
          tariffName,
          details: {
            billingType: billingPayzenType,
            minimumTransaction: minimumTransactionNoLimit
              ? -1
              : statePayzen.minimumTransaction,
            minimumTransactionByStore: minimumTransactionByStoreNoLimit
              ? -1
              : statePayzen.minimumTransactionByStore,
            minimumValue: statePayzen.minimumValue,
            waterfall: statePayzen.waterfall,
            waterfallStore: statePayzen.waterfallStore,
            values,
            valuesByStore,
            countByTransactedStores: statePayzen.countByTransactedStores,
          },
        }
      case 7:
        return {
          tariffName,
          details: {
            billingType: billingPayzenType,
            minimumValue: statePayzen.minimumValue,
            waterfall: statePayzen.waterfall,
            waterfallStore: statePayzen.waterfallStore,
            values,
            valuesByStore,
            storeSetup: statePayzen.storeSetup,
            countByTransactedStores: statePayzen.countByTransactedStores,
          },
        }
      case 8:
        return {
          tariffName,
          details: {
            billingType: billingPayzenType,
            minimumTransaction: minimumTransactionNoLimit
              ? -1
              : statePayzen.minimumTransaction,
            minimumValue: statePayzen.minimumValue,
            waterfall: statePayzen.waterfall,
            values,
          },
        }
      case 9:
        return {
          tariffName,
          details: {
            billingType: billingPayzenType,
            minimumValue: statePayzen.minimumValue,
            waterfall: statePayzen.waterfall,
            values,
          },
        }
      default:
        return null
    }
  }

  const validateFormByBillingPayzenTypeRules = () => {
    const newRowStatusOK = newRow.status === false
    const newRowByStoreStatusOK = newRowByStore.status === false
    const valueNameOK = tariffName && tariffName.trim() !== ''
    const billingPayzenTypeOK = billingPayzenType !== ''
    const stateMinimumTransactionOK = statePayzen.minimumTransaction !== ''
    const stateMinimumTransactionByStoreOK =
      statePayzen.minimumTransactionByStore !== ''
    const stateMinimumValueOK = statePayzen.minimumValue !== ''
    const stateMinimumValueByStoreOK = statePayzen.minimumValueByStore !== ''
    const stateWaterfallOK = statePayzen.waterfall !== ''
    const stateWaterfallStoreOK = statePayzen.waterfallStore !== ''
    const valuesOK = values.length > 0
    const valuesByStoreOK = valuesByStore.length > 0
    const stateCountByTransactedStoresOK =
      statePayzen.countByTransactedStores !== ''
    const stateStoreSetupOK = statePayzen.storeSetup !== ''
    switch (billingPayzenType) {
      case 0:
        if (
          newRowStatusOK &&
          valueNameOK &&
          billingPayzenTypeOK &&
          stateMinimumTransactionOK &&
          stateMinimumValueOK &&
          stateWaterfallOK &&
          valuesOK
        ) {
          return true
        }
        return false

      case 1:
        if (
          newRowStatusOK &&
          valueNameOK &&
          billingPayzenTypeOK &&
          stateMinimumTransactionOK &&
          stateMinimumValueOK &&
          stateMinimumValueByStoreOK &&
          stateWaterfallOK &&
          valuesOK
        ) {
          return true
        }
        return false

      case 2:
        if (
          newRowStatusOK &&
          valueNameOK &&
          billingPayzenTypeOK &&
          stateMinimumTransactionOK &&
          stateMinimumValueOK &&
          stateWaterfallOK &&
          stateWaterfallStoreOK &&
          valuesOK &&
          valuesByStoreOK &&
          stateCountByTransactedStoresOK
        ) {
          return true
        }
        return false

      case 3:
        if (
          newRowStatusOK &&
          newRowByStoreStatusOK &&
          valueNameOK &&
          billingPayzenTypeOK &&
          stateMinimumTransactionOK &&
          stateMinimumValueOK &&
          stateWaterfallOK &&
          stateWaterfallStoreOK &&
          valuesOK &&
          valuesByStoreOK &&
          stateCountByTransactedStoresOK
        ) {
          return true
        }
        return false

      case 4:
        if (
          newRowStatusOK &&
          valueNameOK &&
          billingPayzenTypeOK &&
          stateMinimumTransactionOK &&
          stateMinimumValueOK &&
          stateWaterfallOK &&
          valuesOK
        ) {
          return true
        }
        return false

      case 5:
        if (
          newRowStatusOK &&
          valueNameOK &&
          billingPayzenTypeOK &&
          stateMinimumTransactionOK &&
          stateMinimumValueOK &&
          stateWaterfallOK &&
          valuesOK
        ) {
          return true
        }
        return false

      case 6:
        if (
          newRowStatusOK &&
          newRowByStoreStatusOK &&
          valueNameOK &&
          billingPayzenTypeOK &&
          stateMinimumTransactionOK &&
          stateMinimumTransactionByStoreOK &&
          stateMinimumValueOK &&
          stateWaterfallOK &&
          stateWaterfallStoreOK &&
          valuesOK &&
          valuesByStoreOK &&
          stateCountByTransactedStoresOK
        ) {
          return true
        }
        return false

      case 7:
        if (
          newRowStatusOK &&
          newRowByStoreStatusOK &&
          valueNameOK &&
          billingPayzenTypeOK &&
          stateMinimumValueOK &&
          stateWaterfallOK &&
          stateWaterfallStoreOK &&
          valuesOK &&
          valuesByStoreOK &&
          stateStoreSetupOK &&
          stateCountByTransactedStoresOK
        ) {
          return true
        }
        return false

      case 8:
        if (
          newRowStatusOK &&
          valueNameOK &&
          billingPayzenTypeOK &&
          stateMinimumTransactionOK &&
          stateMinimumValueOK &&
          stateWaterfallOK &&
          valuesOK
        ) {
          return true
        }
        return false

      case 9:
        if (
          newRowStatusOK &&
          valueNameOK &&
          billingPayzenTypeOK &&
          stateMinimumValueOK &&
          stateWaterfallOK &&
          valuesOK
        ) {
          return true
        }
        return false

      default:
        return false
    }
  }

  const handleChangeCumulative = () => {
    setState({ ...statePayzen, waterfall: !statePayzen.waterfall })
  }

  const handleChangeCumulativeStore = () => {
    setState({ ...statePayzen, waterfallStore: !statePayzen.waterfallStore })
  }

  const handleChangeCountByTransactedStores = () => {
    setState({
      ...statePayzen,
      countByTransactedStores: !statePayzen.countByTransactedStores,
    })
  }

  const handleChangeMinimumTransaction = (e) => {
    if (e.target.value && Number(e.target.value) > 0) {
      setState({ ...statePayzen, minimumTransaction: Number(e.target.value) })
      setNewRow({ ...newRow, minLimit: Number(e.target.value) + 1 })
    } else {
      setState({ ...statePayzen, minimumTransaction: 0 })
      setNewRow({ ...newRow, minLimit: 1 })
    }
  }

  const handleChangeMinimumTransactionNoLimit = () => {
    setMinimumTransactionNoLimit(!minimumTransactionNoLimit)
  }

  const handleChangeMinimumTransactionByStore = (e) => {
    if (e.target.value && Number(e.target.value) > 0) {
      setState({
        ...statePayzen,
        minimumTransactionByStore: Number(e.target.value),
      })
    } else {
      setState({ ...statePayzen, minimumTransactionByStore: 0 })
    }
  }

  const handleChangeMinimumTransactionByStoreNoLimit = () => {
    setMinimumTransactionByStoreNoLimit(!minimumTransactionByStoreNoLimit)
  }

  const handleChangeMinimumValue = (e) => {
    e.target.value
      ? setState({
          ...statePayzen,
          minimumValue: parseFloat(e.target.value).toFixed(7),
        })
      : setState({ ...statePayzen, minimumValue: parseFloat(0).toFixed(7) })
  }

  const handleChangeStoreSetup = (e) => {
    e.target.value
      ? setState({
          ...statePayzen,
          storeSetup: parseFloat(e.target.value).toFixed(7),
        })
      : setState({ ...statePayzen, storeSetup: parseFloat(0).toFixed(7) })
  }

  const handleChangeMinimumValueStore = (e) => {
    e.target.value
      ? setState({
          ...statePayzen,
          minimumValueByStore: parseFloat(e.target.value).toFixed(7),
        })
      : setState({
          ...statePayzen,
          minimumValueByStore: parseFloat(0).toFixed(7),
        })
  }

  const handleChangeNewRowLimit = (input, value) => {
    value
      ? setNewRow({ ...newRow, [input]: Number(value) })
      : setNewRow({ ...newRow, [input]: '' })
  }

  const handleChangeNewRowByStoreLimit = (input, value) => {
    value
      ? setNewRowByStore({ ...newRowByStore, [input]: Number(value) })
      : setNewRowByStore({ ...newRowByStore, [input]: '' })
  }

  const handleChangeNewRow = (input, value) => {
    value
      ? setNewRow({ ...newRow, [input]: parseFloat(value).toFixed(7) })
      : setNewRow({ ...newRow, [input]: parseFloat(0).toFixed(7) })
  }

  const handleChangeNewRowByStore = (input, value) => {
    value
      ? setNewRowByStore({
          ...newRowByStore,
          [input]: parseFloat(value).toFixed(7),
        })
      : setNewRowByStore({
          ...newRowByStore,
          [input]: parseFloat(0).toFixed(7),
        })
  }

  const handleNewRow = () => {
    const actualNewRow = newRow
    delete actualNewRow.status

    const maxLimit = newRow.maxLimit !== '' ? newRow.maxLimit : -1

    if (
      (actualNewRow.maxLimit === '' ||
        actualNewRow.maxLimit > actualNewRow.minLimit) &&
      actualNewRow.value !== ''
    ) {
      setValues([
        ...values,
        {
          ...actualNewRow,
          maxLimit,
        },
      ])
      if (actualNewRow.maxLimit !== '') {
        setNewRow({
          ...initialRow,
          minLimit: actualNewRow.maxLimit + 1,
        })
      } else {
        setNewRow({ ...initialRow, status: false })
      }
    }
  }

  const handleNewRowByStore = () => {
    const actualNewRowByStore = newRowByStore
    delete actualNewRowByStore.status

    const maxLimit = newRowByStore.maxLimit !== '' ? newRowByStore.maxLimit : -1

    if (
      (actualNewRowByStore.maxLimit === '' ||
        actualNewRowByStore.maxLimit > actualNewRowByStore.minLimit) &&
      actualNewRowByStore.value !== ''
    ) {
      setValuesByStore([
        ...valuesByStore,
        {
          ...actualNewRowByStore,
          maxLimit,
        },
      ])
      if (actualNewRowByStore.maxLimit !== '') {
        setNewRowByStore({
          ...initialRow,
          minLimit: actualNewRowByStore.maxLimit + 1,
        })
      } else {
        setNewRowByStore({ ...initialRow, status: false })
      }
    }
  }

  const handleDeleteLastRow = () => {
    const newValues = values
    const actualNewRow = values.pop()
    setValues(newValues)
    setNewRow({ ...actualNewRow, maxLimit: '', status: true })
  }

  const handleDeleteLastRowByStore = () => {
    const newValues = valuesByStore
    const actualNewRow = valuesByStore.pop()
    setValuesByStore(newValues)
    setNewRowByStore({ ...actualNewRow, maxLimit: '', status: true })
  }

  const handleEditLastRow = () => {
    const newValues = values
    const actualNewRow = values[values.length - 1]
    newValues.pop()
    if (values.length > 1) {
      setValues(newValues)
    }
    setNewRow({ ...actualNewRow, maxLimit: '', status: true })
  }

  const handleEditLastRowByStore = () => {
    const newValues = valuesByStore
    const actualNewRow = valuesByStore[valuesByStore.length - 1]
    newValues.pop()
    if (valuesByStore.length > 1) {
      setValuesByStore(newValues)
    }
    setNewRowByStore({ ...actualNewRow, maxLimit: '', status: true })
  }

  const handleTab = (e, newValue) => {
    setTab(newValue)
  }

  return (
    <>
      {billingPayzenType === undefined ? (
        <Typography
          variant="subtitle1"
          display="block"
          style={{
            margin: '40px auto',
            fontWeight: 'bold',
            width: '100%',
            textAlign: 'center',
          }}
        >
          {intl.get('phrases.selectBillingPayzenType') ||
            'phrases.selectBillingPayzenType'}
        </Typography>
      ) : (
        <>
          <Typography
            variant="subtitle1"
            display="block"
            style={{ margin: '0px auto', fontWeight: 'bold' }}
          >
            {intl.get('words.description')}
          </Typography>
          <div className={classes.bodySecondDetails}>
            <div className={classes.bodySecondDurationName}>
              <FormControl
                variant="outlined"
                className={classes.bodyFirstNameControl}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  label="Nome"
                  placeholder="Informe o nome da tarifa"
                  fullWidth
                  InputProps={{
                    classes: {
                      root: classes.textFieldTest,
                      input: classes.inputTextfield,
                      notchedOutline: classes.notchedOutline,
                    },
                    disableUnderline: true,
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  onChange={(e) => setTariffName(e.target.value)}
                  value={tariffName}
                />
              </FormControl>
            </div>
            <div className={classes.bodySecondDuration}>
              <FormControl className={classes.checkbox}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      classes={{
                        checked: classes.checked,
                      }}
                      checked={statePayzen.waterfall}
                      onChange={handleChangeCumulative}
                    />
                  }
                  label="Cumulativo"
                />
              </FormControl>
            </div>
            <div className={classes.bodySecondDuration}>
              <FormControl
                variant="outlined"
                className={classes.bodyFirstNameControl}
              >
                <TextField
                  size="small"
                  variant="outlined"
                  margin="dense"
                  label="Mín. Transações"
                  type="number"
                  fullWidth
                  InputProps={{
                    classes: {
                      root: classes.textFieldTest,
                      input: classes.inputTextfield,
                      notchedOutline: classes.notchedOutline,
                    },
                    disableUnderline: true,
                  }}
                  onChange={handleChangeMinimumTransaction}
                  value={
                    minimumTransactionNoLimit
                      ? 0
                      : statePayzen.minimumTransaction
                  }
                  disabled={values.length || minimumTransactionNoLimit}
                />
              </FormControl>
            </div>
            {billingPayzenType !== 7 && billingPayzenType !== 9 && (
              <div className={classes.bodySecondDuration}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      classes={{
                        checked: classes.checked,
                      }}
                      checked={minimumTransactionNoLimit}
                      onChange={handleChangeMinimumTransactionNoLimit}
                    />
                  }
                  label="Sem limite"
                />
              </div>
            )}
          </div>
          <div className={classes.bodySecondDetails}>
            {(billingPayzenType === 2 ||
              billingPayzenType === 3 ||
              billingPayzenType === 6 ||
              billingPayzenType === 7) && (
              <div className={classes.bodySecondDuration}>
                <FormControl className={classes.checkbox}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        classes={{
                          checked: classes.checked,
                        }}
                        checked={statePayzen.waterfallStore}
                        onChange={handleChangeCumulativeStore}
                      />
                    }
                    label="Cumulativo Lojas"
                  />
                </FormControl>
              </div>
            )}
            <div className={classes.bodySecondDuration}>
              <FormControl
                variant="outlined"
                className={classes.bodyFirstNameControl}
              >
                <TextField
                  fullWidth
                  size="small"
                  variant="outlined"
                  margin="dense"
                  placeholder="0,0000000"
                  label={`Mín. ${intl.get('words.value')}`}
                  InputProps={{
                    classes: {
                      root: classes.textFieldTest,
                      input: classes.currency,
                      notchedOutline: classes.notchedOutline,
                    },
                    disableUnderline: true,
                    inputComponent: TextFieldCurrency,
                    startAdornment: (
                      <InputAdornment
                        disableTypography
                        style={{ color: 'lightGray' }}
                        position="start"
                      >
                        {currencyData?.symbol}
                      </InputAdornment>
                    ),
                  }}
                  onChange={handleChangeMinimumValue}
                  value={statePayzen.minimumValue}
                />
              </FormControl>
            </div>
            {billingPayzenType === 1 && (
              <div className={classes.bodySecondDuration}>
                <FormControl
                  variant="outlined"
                  className={classes.bodyFirstNameControl}
                >
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    placeholder="0,0000000"
                    label={`Mín. ${intl.get('words.value')} por Loja`}
                    InputProps={{
                      classes: {
                        root: classes.textFieldTest,
                        input: classes.currency,
                        notchedOutline: classes.notchedOutline,
                      },
                      disableUnderline: true,
                      inputComponent: TextFieldCurrency,
                      startAdornment: (
                        <InputAdornment
                          disableTypography
                          style={{ color: 'lightGray' }}
                          position="start"
                        >
                          {currencyData?.symbol}
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChangeMinimumValueStore}
                    value={statePayzen.minimumValueByStore}
                  />
                </FormControl>
              </div>
            )}

            {billingPayzenType === 6 && (
              <>
                <div className={classes.bodySecondDuration}>
                  <FormControl
                    variant="outlined"
                    className={classes.bodyFirstNameControl}
                  >
                    <TextField
                      size="small"
                      variant="outlined"
                      margin="dense"
                      label="Mín. Transações por Loja"
                      type="number"
                      fullWidth
                      InputProps={{
                        classes: {
                          root: classes.textFieldTest,
                          input: classes.inputTextfield,
                          notchedOutline: classes.notchedOutline,
                        },
                        disableUnderline: true,
                      }}
                      onChange={handleChangeMinimumTransactionByStore}
                      value={
                        minimumTransactionByStoreNoLimit
                          ? 0
                          : statePayzen.minimumTransactionByStore
                      }
                      disabled={minimumTransactionByStoreNoLimit}
                    />
                  </FormControl>
                </div>
                <div className={classes.bodySecondDuration}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        classes={{
                          checked: classes.checked,
                        }}
                        checked={minimumTransactionByStoreNoLimit}
                        onChange={handleChangeMinimumTransactionByStoreNoLimit}
                      />
                    }
                    label="Sem limite"
                  />
                </div>
              </>
            )}
            {billingPayzenType === 7 && (
              <div className={classes.bodySecondDuration}>
                <FormControl
                  variant="outlined"
                  className={classes.bodyFirstNameControl}
                >
                  <TextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    margin="dense"
                    placeholder="0,0000000"
                    label="Setup por Loja"
                    InputProps={{
                      classes: {
                        root: classes.textFieldTest,
                        input: classes.currency,
                        notchedOutline: classes.notchedOutline,
                      },
                      disableUnderline: true,
                      inputComponent: TextFieldCurrency,
                      startAdornment: (
                        <InputAdornment
                          disableTypography
                          style={{ color: 'lightGray' }}
                          position="start"
                        >
                          {currencyData?.symbol}
                        </InputAdornment>
                      ),
                    }}
                    onChange={handleChangeStoreSetup}
                    value={statePayzen.storeSetup}
                  />
                </FormControl>
              </div>
            )}
            {(billingPayzenType === 2 ||
              billingPayzenType === 3 ||
              billingPayzenType === 6 ||
              billingPayzenType === 7) && (
              <div className={classes.bodySecondDetails}>
                <FormControl
                  variant="outlined"
                  className={classes.bodyFirstNameControl}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        color="primary"
                        classes={{
                          checked: classes.checked,
                        }}
                        checked={statePayzen.countByTransactedStores}
                        onChange={handleChangeCountByTransactedStores}
                      />
                    }
                    label="Contar por lojas transacionadas"
                  />
                </FormControl>
              </div>
            )}
          </div>

          {(billingPayzenType === 2 ||
            billingPayzenType === 3 ||
            billingPayzenType === 6 ||
            billingPayzenType === 7) && (
            <Tabs
              centered
              value={tab}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTab}
              aria-label="disabled tabs example"
              className={classes.tabs}
            >
              <Tab label="Transações" />
              <Tab label="Lojas" />
            </Tabs>
          )}

          {tab === 0 && (
            <>
              <Typography
                variant="subtitle1"
                display="block"
                style={{
                  margin: '0px auto',
                  fontWeight: 'bold',
                  borderTop: '1px solid #001064',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                {intl.get('words.slices')}
              </Typography>
              <div id="dial-slices" className={classes.bodySecondTable}>
                <Table
                  style={{
                    width: '90%',
                    margin: '0px auto',
                  }}
                >
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell align="center">
                        {intl.get('words.start')}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {intl.get('words.end')}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {intl.get('words.value')}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.map((row, index) => (
                      <TableRow key={row.minLimit} className={classes.tableRow}>
                        <TableCell
                          align="center"
                          className={classes.tableCell}
                          component="th"
                          scope="row"
                        >
                          {row.minLimit}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.maxLimit > 0
                            ? row.maxLimit
                            : intl.get('phrases.noLimit')}
                        </TableCell>
                        <TableCell align="right" className={classes.tableCell}>
                          <NumberFormat
                            displayType="text"
                            prefix={currencyData?.symbol}
                            thousandSeparator={currencyData?.thousandSeparator}
                            decimalSeparator={currencyData?.decimalSeparator}
                            allowNegative={false}
                            isNumericString
                            decimalScale={7}
                            value={row.value}
                          />
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {index === values.length - 1 && !newRow.status && (
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={handleEditLastRow}
                              className={classes.iconButton}
                              size="small"
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                    {newRow.status && (
                      <TableRow key="newRow" className={classes.tableRow}>
                        <TableCell
                          align="center"
                          className={classes.tableCell}
                          component="th"
                          scope="row"
                        >
                          {newRow.minLimit}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            autoFocus
                            inputRef={inputRef}
                            fullWidth
                            size="small"
                            variant="outlined"
                            margin="dense"
                            placeholder="Sem limite"
                            InputProps={{
                              classes: {
                                root: classes.textFieldTest,
                                input: classes.inputTextfield,
                                notchedOutline: classes.notchedOutline,
                              },
                              disableUnderline: true,
                            }}
                            onChange={(e) =>
                              handleChangeNewRowLimit(
                                'maxLimit',
                                e.target.value
                              )
                            }
                            value={newRow.maxLimit}
                            type="number"
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            margin="dense"
                            placeholder="0,0000000"
                            InputProps={{
                              classes: {
                                root: classes.textFieldTest,
                                input: classes.currency,
                                notchedOutline: classes.notchedOutline,
                              },
                              disableUnderline: true,
                              inputComponent: TextFieldCurrency,
                              startAdornment: (
                                <InputAdornment
                                  disableTypography
                                  style={{ color: 'lightGray' }}
                                  position="start"
                                >
                                  {currencyData?.symbol}
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) =>
                              handleChangeNewRow('value', e.target.value)
                            }
                            value={newRow.value}
                          />
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {(newRow.maxLimit === '' ||
                            newRow.maxLimit > newRow.minLimit) &&
                            newRow.value !== '' && (
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={handleNewRow}
                                className={classes.iconButton}
                                size="small"
                              >
                                <CheckIcon />
                              </IconButton>
                            )}
                          {values.length > 0 && (
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={handleDeleteLastRow}
                              className={classes.iconButton}
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
          {tab === 1 && (
            <>
              <Typography
                variant="subtitle1"
                display="block"
                style={{
                  margin: '0px auto',
                  fontWeight: 'bold',
                  borderTop: '1px solid #001064',
                  width: '100%',
                  textAlign: 'center',
                }}
              >
                Total
              </Typography>
              <div id="dial-slices" className={classes.bodySecondTable}>
                <Table
                  style={{
                    width: '90%',
                    margin: '0px auto',
                  }}
                >
                  <TableHead>
                    <TableRow className={classes.tableRow}>
                      <TableCell align="center">
                        {intl.get('words.start')}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {intl.get('words.end')}
                      </TableCell>
                      <TableCell align="right" className={classes.tableCell}>
                        {intl.get('words.value')}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        Ações
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {valuesByStore.map((row, index) => (
                      <TableRow key={row.minLimit} className={classes.tableRow}>
                        <TableCell
                          align="center"
                          className={classes.tableCell}
                          component="th"
                          scope="row"
                        >
                          {row.minLimit}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.maxLimit > 0
                            ? row.maxLimit
                            : intl.get('phrases.noLimit')}
                        </TableCell>
                        <TableCell align="right" className={classes.tableCell}>
                          <NumberFormat
                            displayType="text"
                            prefix={currencyData?.symbol}
                            thousandSeparator={currencyData?.thousandSeparator}
                            decimalSeparator={currencyData?.decimalSeparator}
                            allowNegative={false}
                            isNumericString
                            decimalScale={7}
                            value={row.value}
                          />
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {index === valuesByStore.length - 1 &&
                            !newRowByStore.status && (
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={handleEditLastRowByStore}
                                className={classes.iconButton}
                                size="small"
                              >
                                <EditIcon />
                              </IconButton>
                            )}
                        </TableCell>
                      </TableRow>
                    ))}
                    {newRowByStore.status && (
                      <TableRow key="newRow" className={classes.tableRow}>
                        <TableCell
                          align="center"
                          className={classes.tableCell}
                          component="th"
                          scope="row"
                        >
                          {newRowByStore.minLimit}
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            autoFocus
                            inputRef={inputRef}
                            fullWidth
                            size="small"
                            variant="outlined"
                            margin="dense"
                            placeholder="Sem limite"
                            InputProps={{
                              classes: {
                                root: classes.textFieldTest,
                                input: classes.inputTextfield,
                                notchedOutline: classes.notchedOutline,
                              },
                              disableUnderline: true,
                            }}
                            onChange={(e) =>
                              handleChangeNewRowByStoreLimit(
                                'maxLimit',
                                e.target.value
                              )
                            }
                            value={newRowByStore.maxLimit}
                            type="number"
                          />
                        </TableCell>
                        <TableCell className={classes.tableCell}>
                          <TextField
                            fullWidth
                            size="small"
                            variant="outlined"
                            margin="dense"
                            placeholder="0,0000000"
                            InputProps={{
                              classes: {
                                root: classes.textFieldTest,
                                input: classes.currency,
                                notchedOutline: classes.notchedOutline,
                              },
                              disableUnderline: true,
                              inputComponent: TextFieldCurrency,
                              startAdornment: (
                                <InputAdornment
                                  disableTypography
                                  style={{ color: 'lightGray' }}
                                  position="start"
                                >
                                  {currencyData?.symbol}
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) =>
                              handleChangeNewRowByStore('value', e.target.value)
                            }
                            value={newRowByStore.value}
                          />
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {(newRowByStore.maxLimit === '' ||
                            newRowByStore.maxLimit > newRowByStore.minLimit) &&
                            newRowByStore.value !== '' && (
                              <IconButton
                                color="primary"
                                aria-label="upload picture"
                                component="span"
                                onClick={handleNewRowByStore}
                                className={classes.iconButton}
                                size="small"
                              >
                                <CheckIcon />
                              </IconButton>
                            )}
                          {valuesByStore.length > 0 && (
                            <IconButton
                              color="primary"
                              aria-label="upload picture"
                              component="span"
                              onClick={handleDeleteLastRowByStore}
                              className={classes.iconButton}
                              size="small"
                            >
                              <DeleteIcon />
                            </IconButton>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </div>
            </>
          )}
        </>
      )}
    </>
  )
}
export default PayzenDetails
