import {
  CustomPaging,
  CustomSummary,
  IntegratedSelection,
  PagingState,
  SelectionState,
  SortingState,
  SummaryState,
  Table,
} from '@devexpress/dx-react-grid'
import {
  DragDropProvider,
  Grid,
  PagingPanel,
  Table as TableDX,
  TableBandHeader,
  TableColumnReordering,
  TableColumnResizing,
  TableColumnVisibility,
  TableFixedColumns,
  TableHeaderRow,
  TableSelection,
  TableSummaryRow,
  VirtualTable,
} from '@devexpress/dx-react-grid-material-ui'
import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import NumberFormat from 'react-number-format'
import { IconButton, Tooltip, Typography } from '../../core'
import { ArrowDownward, ArrowUpward } from '../../icons'
import { intl } from '../../translation'
import { secondsToTime } from '../../utils'
import {
  Cell,
  CellTotal,
  EmptyRowTotal,
  HeaderCell,
  HeaderRow,
  HeaderTitle,
  Row,
  RowTotal,
  SelectCell,
  SelectHeaderCell,
  TypeProviders,
} from './components'
import CustomCell from './components/CustomCell'
import LoadingWrapper from './LoadingWrapper'
import NoData from './NoData'
import PagingPanelMessages from './PagingPanelMessages'
import { useStyles } from './styles'

const Root = (props) => <Grid.Root {...props} style={{ height: '100%' }} />

const TableContainerBase = ({ ...restProps }) => {
  const classes = useStyles()
  return <TableDX.Container {...restProps} className={classes.tableContainer} />
}

export const TableData = (props) => {
  const {
    carriers,
    className,
    cleanSelected,
    hasBillingBands,
    hasSelection,
    hasTotal,
    hiddenPages,
    isLoading,
    // list,
    onBilletDownload,
    onDoubleClick,
    onInvoiceDownload,
    onPageChange,
    onPageSizeChange,
    onPdfDownload,
    onSelectionChange,
    onSettingsChange,
    onSingleClick,
    onSorting,
    page,
    limit,
    rowsSelected,
    settings,
    singleRowSelected,
    sorting,
    title,
    totalCount,
    totals,
    currencyData,
    currencyListOptions,
    canDeleteAdjust,
    onNewReleaseInsert,
  } = props
  const classes = useStyles()
  const firstRender = useRef(true)

  const [internalSettings, setInternalSettings] = useState({
    fixedLeftColumns: [],
    fixedRightColumns: [],
    order: [],
    columns: [],
    pageSizes: [],
    hiddenColumns: [],
    margimGroupColumns: [],
    purchaseGroupColumns: [],
    salesGroupColumns: [],
    providers: {
      date: [],
      byte: [],
      bool: {},
      percentage: [],
      commercial: [],
      actions: [],
    },
  })
  const [fakeReload, setFakeReload] = useState(false)
  const [selection, setSelection] = useState([])

  const [totalSummaryItems, setTotalSummaryItems] = useState([])

  const [columnBands, setColumnBands] = useState([])

  const [list, setList] = useState([])

  const [sortingDirection, setSortingDirection] = useState('')
  const [sortingColumn, setSortingColumn] = useState('')

  const [totalRowWidth, setTotalRowWidth] = useState('')

  const salesGroupColumns = internalSettings?.salesGroupColumns
  const purchaseGroupColumns = internalSettings?.purchaseGroupColumns
  // Backend need changes this incorrect margimGroupColumns name
  const marginGroupColumns = internalSettings?.margimGroupColumns
  const colouredCurrencyGroupColumns =
    internalSettings?.colouredCurrencyGroupColumns
  const totalRowHasTitle = internalSettings?.totalRowHasTitle

  const virtualTable = React.createRef()

  useEffect(() => {
    if (list?.length === 0 && props?.list?.length === 0) {
    } else {
      setList(props.list)
    }
  }, [props.list])

  useEffect(() => {
    if (virtualTable?.current) {
      const table = document.querySelector('table')

      if (table) {
        table?.offsetWidth &&
          table?.offsetWidth !== totalRowWidth &&
          setTotalRowWidth(table?.offsetWidth)
      }
    }
  }, [virtualTable])

  useEffect(() => {
    const newTotals = []

    if (totals && !_.isEmpty(totals)) {
      Object.keys(totals).map((item) => {
        if (item !== 'currency') {
          newTotals.push({
            ...totals[item],
            columnName: item,
            currency: totals?.currency,
          })
        }
      })
    } else {
      newTotals.push({})
    }

    setTotalSummaryItems(newTotals)
  }, [totals])

  useEffect(() => {
    const salesColumns = { children: [] }
    const purchaseColumns = { children: [] }
    const marginColumns = { children: [] }

    const groupedHeaders = []

    if (salesGroupColumns) {
      salesGroupColumns?.length > 0 &&
        salesGroupColumns.map((item) => {
          salesColumns.children.push({
            columnName: item,
          })
        })

      groupedHeaders.push(salesColumns)
    }

    if (purchaseGroupColumns) {
      purchaseGroupColumns?.length > 0 &&
        purchaseGroupColumns.map((item) => {
          purchaseColumns.children.push({
            columnName: item,
          })
        })

      groupedHeaders.push(purchaseColumns)
    }

    if (marginGroupColumns) {
      marginGroupColumns?.length > 0 &&
        marginGroupColumns.map((item) => {
          marginColumns.children.push({
            columnName: item,
          })
        })

      groupedHeaders.push(marginColumns)
    }

    if (groupedHeaders.length > 0 && hasBillingBands) {
      setColumnBands(groupedHeaders)
    }
  }, [salesGroupColumns, purchaseGroupColumns, marginGroupColumns])

  const getTotalSummaryValues = () => {
    return totalSummaryItems.map((summary) => {
      const { columnName, type } = summary
      const newValue =
        totals &&
        columnName &&
        convertTotalValue(
          type,
          totals[columnName] ? totals[columnName].value : 0,
          summary?.currency?.value
        )
      return newValue
    })
  }

  const convertTotalValue = (type, value, currencyRow) => {
    const currencySelected = currencyListOptions?.find(
      (item) => item?.code === currencyRow?.code
    )

    switch (type) {
      case 'currency':
        return (
          <NumberFormat
            displayType="text"
            prefix={currencySelected?.symbol}
            thousandSeparator={currencySelected?.thousandSeparator}
            decimalSeparator={currencySelected?.decimalSeparator}
            allowNegative
            isNumericString
            decimalScale={2}
            fixedDecimalScale
            allowLeadingZeros
            value={value}
          />
        )
      case 'porcent':
        return `${(value * 100)?.toFixed(2)}%`

      case 'count':
        return value
          ? `${value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
          : ''

      case 'time':
        return secondsToTime(value)
      default:
        return value
    }
  }

  useEffect(() => {
    setSelection([])
  }, [page, list, sorting, cleanSelected])

  useEffect(() => {
    if (hasSelection && typeof onSelectionChange === 'function') {
      if (firstRender.current) {
        firstRender.current = false
        return
      }

      const selectedRows = []

      for (let i = 0; i < selection.length; i++) {
        selectedRows.push(list[selection[i]])
      }

      onSelectionChange(selectedRows)
    }
  }, [selection])

  useEffect(() => {
    const columns =
      settings?.columns &&
      settings?.columns.reduce((accumulator, e) => {
        e.title = `${intl.get(`titles.${e.name}`)}`
        if (e.cellValue) {
          e.getCellValue = (row) => {
            const path = e.cellValue.split('.')
            let local = row
            try {
              for (let i = 0; i < path.length; i += 1) {
                local = local[path[i]]
              }
            } catch (e) {
              return ''
            }
            return local || ''
          }
        }

        accumulator.push(e)

        return accumulator
      }, [])

    if (columns.some((column) => column.name === 'type')) {
      columns.push({
        name: 'delete',
        columnName: 'delete',
        align: 'center',
        width: 120,
        canHide: false,
        isFixed: true,
        isBolder: false,
        hasOnClick: false,
        isDraggable: false,
        sortingEnabled: false,
        sortedByDefault: false,
        title: 'Deletar',
      })
    }

    setInternalSettings({
      ...settings,
      columns,
    })
  }, [settings])

  const order = useMemo(
    () => [
      ...internalSettings?.fixedLeftColumns,
      ...internalSettings?.order,
      ...internalSettings?.fixedRightColumns,
    ],
    [internalSettings.order]
  )

  const hasFixedColumns = useMemo(
    () =>
      internalSettings?.fixedLeftColumns.length > 0 ||
      internalSettings?.fixedRightColumns.length > 0,
    [internalSettings?.fixedLeftColumns, internalSettings?.fixedRightColumns]
  )

  const noData = ({ colSpan }) => {
    if (isLoading) {
      return <LoadingWrapper colSpan={colSpan} classes={classes} />
    }

    return <NoData colSpan={colSpan} classes={classes} />
  }

  const handleSorting = (newSort) => {
    const newObj = { ...newSort[0] }

    newObj.direction = sortingDirection || ''

    if (newObj.direction === '') {
      onSorting([])
    } else {
      onSorting([newObj])
    }
  }

  const updateSettings = (type) => (newSettings) => {
    if (!newSettings) {
      // || settings?.isMock
      return
    }

    if (
      type === 'onOrderChange' &&
      newSettings?.length > 0 &&
      JSON.stringify(newSettings) !== JSON.stringify(order)
    ) {
      const updatedSettings = { ...internalSettings }

      updatedSettings.order = newSettings.reduce(
        (accumulator, currentValue) => {
          if (
            !internalSettings?.fixedRightColumns.includes(currentValue) &&
            !internalSettings?.fixedLeftColumns.includes(currentValue)
          ) {
            accumulator.push(currentValue)
          }

          return accumulator
        },
        []
      )

      setInternalSettings(updatedSettings)
      onSettingsChange(updatedSettings)
      setFakeReload(true)
    }

    if (type === 'onColumnWidthsChange') {
      const updatedSettings = { ...internalSettings }
      const changedColumn = newSettings.find((e) => Object.keys(e).length === 2)

      const updatedColumnIndex = updatedSettings?.columns.findIndex(
        (e) => e.columnName === changedColumn.columnName
      )

      updatedSettings.columns[updatedColumnIndex] = {
        ...updatedSettings?.columns[updatedColumnIndex],
        ...changedColumn,
      }
      setInternalSettings(updatedSettings)
      onSettingsChange(updatedSettings)
      setFakeReload(true)
    }
  }

  const PagingContainer = ({ ...restProps }) => {
    return (
      internalSettings?.columns.length !==
        internalSettings?.hiddenColumns.length && (
        <PagingPanel.Container {...restProps} className={classes.pagingPanel} />
      )
    )
  }

  useEffect(() => {
    setTimeout(() => {
      setFakeReload(false)
    }, 500)
  }, [fakeReload])

  useEffect(() => {
    setSelection([])
  }, [page])

  const Providers = () =>
    useMemo(() => {
      if (!internalSettings.providers || !internalSettings?.providers) {
        return <></>
      }

      return (
        <TypeProviders
          settingsProviders={internalSettings.providers}
          carriers={carriers || []}
          children={props.children}
          onBilletDownload={onBilletDownload}
          onInvoiceDownload={onInvoiceDownload}
          onPdfDownload={onPdfDownload}
          currencyData={currencyData}
          currencyListOptions={currencyListOptions}
        />
      )
    }, [internalSettings.providers])

  const SortLabel = (props) => {
    const { onSort, children, direction, column } = props
    const sortingEnabled = column?.sortingEnabled

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          padding: '0 2px',
          width: '95%',
          cursor: 'default',
        }}
      >
        {children}
        {sortingEnabled && (
          <SortingIcon direction={direction} onSort={onSort} column={column} />
        )}
      </div>
    )
  }

  const SortingIcon = ({ direction, onSort, column }) => {
    const handleClick = (col, dir) => {
      if (col === sortingColumn && dir === sortingDirection) {
        setSortingDirection('')
        setSortingColumn('')
        return
      }

      setSortingDirection(dir)
      setSortingColumn(col)
    }

    return (
      <div className={classes.sortButtonsContainer}>
        <Tooltip title={intl.get('words.descendent') || 'words.descendent'}>
          <IconButton
            size="small"
            onClick={onSort}
            className={classNames({
              [classes.sortButtonSelected]: direction === 'desc',
            })}
          >
            <ArrowUpward
              onClick={() => handleClick(column?.name, 'desc')}
              className={classNames(classes.sortButton, {
                [classes.sortButtonSelected]: direction === 'desc',
              })}
            />
          </IconButton>
        </Tooltip>
        <Tooltip title={intl.get('words.ascendent') || 'words.ascendent'}>
          <IconButton
            size="small"
            onClick={onSort}
            className={classNames({
              [classes.sortButtonSelected]: direction === 'asc',
            })}
          >
            <ArrowDownward
              onClick={() => handleClick(column?.name, 'asc')}
              className={classNames(classes.sortButton, {
                [classes.sortButtonSelected]: direction === 'asc',
              })}
            />
          </IconButton>
        </Tooltip>
      </div>
    )
  }

  return (
    <div className={classNames(classes.root, className)}>
      {(isLoading || fakeReload) && (
        <LoadingWrapper className={classes.loading} />
      )}
      {!isLoading && !fakeReload && (
        <Grid
          rows={list || []}
          columns={internalSettings?.columns}
          rootComponent={Root}
        >
          <Providers />
          <SummaryState totalItems={totalSummaryItems} />

          {hasSelection && typeof onSelectionChange === 'function' && (
            <SelectionState
              selection={selection}
              onSelectionChange={setSelection}
            />
          )}

          {hasSelection && typeof onSelectionChange === 'function' && (
            <IntegratedSelection />
          )}

          <SortingState
            sorting={sorting}
            columnExtensions={internalSettings?.columns}
            onSortingChange={handleSorting}
          />

          <PagingState
            currentPage={page - 1}
            pageSize={limit}
            onCurrentPageChange={onPageChange}
            onPageSizeChange={onPageSizeChange}
          />

          <CustomPaging totalCount={totalCount} />

          <DragDropProvider />

          {title && (
            <div className={classes.divTitle}>
              <Typography variant="h6">{title}</Typography>
            </div>
          )}

          <VirtualTable
            ref={virtualTable}
            height="auto"
            rowComponent={(props) =>
              Row({
                ...props,
                onSingleClick,
                singleRowSelected,
              })
            }
            cellComponent={(props) =>
              props.column.name === 'delete' ? (
                <CustomCell
                  {...props}
                  canDeleteAdjust={canDeleteAdjust}
                  onNewReleaseInsert={onNewReleaseInsert}
                />
              ) : (
                Cell({
                  ...props,
                  onDoubleClick,
                  onSingleClick,
                  singleRowSelected,
                  salesGroupColumns,
                  purchaseGroupColumns,
                  marginGroupColumns,
                  colouredCurrencyGroupColumns,
                  totalRowHasTitle,
                  selection,
                })
              )
            }
            noDataCellComponent={noData}
            columnExtensions={internalSettings?.columns}
            stubCellComponent={Cell}
            containerComponent={TableContainerBase}
            stubHeaderCellComponent={HeaderCell}
          />

          <TableColumnReordering
            order={order}
            onOrderChange={updateSettings('onOrderChange')}
          />

          <TableColumnResizing
            columnWidths={internalSettings?.columns}
            onColumnWidthsChange={updateSettings('onColumnWidthsChange')}
          />

          <TableHeaderRow
            showSortingControls
            messages={{
              sortingHint: intl.get(`words.sort`),
            }}
            cellComponent={(props) =>
              HeaderCell({
                ...props,
                salesGroupColumns,
                purchaseGroupColumns,
                marginGroupColumns,
                totalRowHasTitle,
              })
            }
            titleComponent={HeaderTitle}
            rowComponent={HeaderRow}
            sortLabelComponent={SortLabel}
          />
          {columnBands && columnBands.length > 0 && (
            <TableBandHeader columnBands={columnBands} />
          )}

          <CustomSummary totalValues={getTotalSummaryValues()} />

          {hasTotal && (
            <TableSummaryRow
              totalRowComponent={
                totals && !_.isEmpty(totals)
                  ? RowTotal
                  : !currencyData?.code
                  ? () => <EmptyRowTotal rowWidth={totalRowWidth} />
                  : () => <></>
              }
              itemComponent={CellTotal}
            />
          )}

          {hasSelection && typeof onSelectionChange === 'function' && (
            <TableSelection
              highlightRow
              showSelectAll
              rowComponent={Row}
              cellComponent={SelectCell}
              headerCellComponent={(props) =>
                SelectHeaderCell({
                  ...props,
                  selected:
                    rowsSelected?.length &&
                    list?.length &&
                    rowsSelected.length === list.length,
                  totalRows: list?.length,
                  totalRowsSelected: rowsSelected?.length,
                })
              }
            />
          )}

          {hasFixedColumns && (
            <TableFixedColumns
              cellComponent={(props) =>
                Cell({
                  ...props,
                })
              }
            />
          )}
          {!hiddenPages && !totalRowHasTitle && (
            <PagingPanel
              showAll
              containerComponent={PagingContainer}
              pageSizes={internalSettings?.pageSizes}
              messages={{
                info: PagingPanelMessages.info,
                rowsPerPage: intl.get('default.list.rowsPerPage'),
                showAll: intl.get('default.list.showAll'),
              }}
            />
          )}
          <TableColumnVisibility
            hiddenColumnNames={internalSettings?.hiddenColumns}
            messages={{ noColumns: intl.get('phrases.noColumns') }}
            noColumns
          />
        </Grid>
      )}
    </div>
  )
}

TableData.defaultProps = {
  settings: {
    fixedLeftColumns: [],
    fixedRightColumns: [],
    order: [],
    columns: [],
    pageSizes: [],
    hiddenColumns: [],
    margimGroupColumns: [],
    purchaseGroupColumns: [],
    salesGroupColumns: [],
    providers: {
      date: [],
      byte: [],
      bool: {},
      percentage: [],
      commercial: [],
      actions: [],
    },
  },
  page: 0,
  list: [],
  limit: 0,
  sorting: [],
  totalCount: 0,
  isLoading: true,
  onSorting: () => {},
  onPageChange: () => {},
  onDoubleClick: () => {},
  onSingleClick: () => {},
  onPageSizeChange: () => {},
  onSettingsChange: () => {},
}

TableData.propTypes = {
  list: PropTypes.array.isRequired,
  hasSelection: PropTypes.bool,
  onSelectionChange: PropTypes.func,
  page: PropTypes.number.isRequired,
  sorting: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSorting: PropTypes.func.isRequired,
  limit: PropTypes.number.isRequired,
  settings: PropTypes.object.isRequired,
  onPageChange: PropTypes.func.isRequired,
  totalCount: PropTypes.number.isRequired,
  onSingleClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onPageSizeChange: PropTypes.func.isRequired,
  onSettingsChange: PropTypes.func.isRequired,
  onBilletDownload: PropTypes.func,
  onInvoiceDownload: PropTypes.func,
  onPdfDownload: PropTypes.func,
}
