import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { useStyles } from './styles'
import {
  Add,
  DoubleButtons,
  Download,
  Excel,
  Export,
  ExportPDF,
  ListButton,
  MonthNavigation,
  MoveToFolder,
  Multifunctional,
  MultifunctionalRight,
  Notifications,
  Publish,
  ProcessCounter,
  Recharge,
  Refresh,
  SelectNavigation,
  StatusChange,
  TitleDetails,
  Visibility,
} from './Buttons'
import Edit from './Buttons/Edit'
import { useWebSocketContext } from '../../../../contexts/WebSocket'
import intl from 'react-intl-universal'

export const TableDataHeader = ({
  handleButtonClick,
  hasAdd,
  hasDoubleButtons,
  hasDownload,
  hasExcel,
  hasExport,
  hasExportPDF,
  hasFileImport,
  hasListButton,
  hasMonthNavigation,
  hasMoveToFolder,
  hasMultifunctional,
  hasMultifunctionalRight,
  hasNotifications,
  hasProcessCounter,
  hasRecharge,
  hasRefresh,
  hasSelectNavigation,
  hasStatusChange,
  hasTitleDetails,
  hasVisibility,
  hasEdit,
  screen,
  ...restProps
}) => {
  const classes = useStyles()

  const [isLoadingAction, setIsLoadingAction] = useState({
    exportExcel: false,
    downloadInvoice: false,
  })
  const { processCounters } = useWebSocketContext()
  const processCounterScreen = processCounters[screen]

  useEffect(() => {
    if (
      processCounterScreen?.exportExcel?.status === 'PENDING' ||
      processCounterScreen?.exportExcel?.status === 'STARTED'
    ) {
      setIsLoadingAction({ exportExcel: true })
    }
    if (
      processCounterScreen?.downloadInvoice?.status === 'PENDING' ||
      processCounterScreen?.downloadInvoice?.status === 'STARTED'
    ) {
      setIsLoadingAction({ downloadInvoice: true })
    }
  }, [processCounterScreen])

  const handleResetLoading = (action) => {
    setIsLoadingAction((state) => ({ ...state, [action]: false }))
  }

  return (
    <div className={classes.listHeader}>
      <div data-testid="leftDiv" className={classes.buttonsLeft}>
        {hasEdit && (
          <Edit {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasAdd && <Add {...restProps} handleButtonClick={handleButtonClick} />}
        {hasMultifunctional && (
          <Multifunctional
            {...restProps}
            handleButtonClick={handleButtonClick}
          />
        )}
        {hasListButton && (
          <ListButton {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasDoubleButtons && (
          <DoubleButtons {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasProcessCounter && (
          <ProcessCounter
            {...restProps}
            handleButtonClick={handleButtonClick}
          />
        )}
      </div>
      <div data-testid="centerDiv" className={classes.buttonsCenter}>
        {hasMonthNavigation && (
          <MonthNavigation
            {...restProps}
            handleButtonClick={handleButtonClick}
          />
        )}
        {hasSelectNavigation && (
          <SelectNavigation
            {...restProps}
            handleButtonClick={handleButtonClick}
          />
        )}
        {hasTitleDetails && (
          <TitleDetails {...restProps} handleButtonClick={handleButtonClick} />
        )}
      </div>
      <div data-testid="rightDiv" className={classes.buttonsRight}>
        {hasMultifunctionalRight && (
          <MultifunctionalRight
            {...restProps}
            handleButtonClick={handleButtonClick}
          />
        )}
        {hasFileImport && (
          <Publish {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasStatusChange && (
          <StatusChange {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasDownload && (
          <Download
            defaultTexts={{
              errorProcess: intl.get('phrases.errorGenerateInvoice'),
              doneProcess: intl.get('phrases.generatedInvoice'),
              preparingProcess: intl.get('words.invoices'),
              statedProcess: intl.get('phrases.generatingInvoice'),
            }}
            processCounter={processCounterScreen?.downloadInvoice}
            isLoading={isLoadingAction?.downloadInvoice}
            handleReset={() => handleResetLoading('downloadInvoice')}
            handleButtonClick={handleButtonClick}
            {...restProps}
          />
        )}
        {hasExport && (
          <Export {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasMoveToFolder && (
          <MoveToFolder {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasRecharge && (
          <Recharge {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasRefresh && (
          <Refresh {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasExportPDF && (
          <ExportPDF {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasVisibility && (
          <Visibility {...restProps} handleButtonClick={handleButtonClick} />
        )}
        {hasExcel && (
          <Excel
            defaultTexts={{
              errorProcess: intl.get('phrases.exportationError'),
              doneProcess: intl.get('phrases.exportationFinished'),
              preparingProcess: intl.get('words.exportation'),
              statedProcess: intl.get('phrases.exportingTable'),
            }}
            processCounter={processCounterScreen?.exportExcel}
            isLoading={isLoadingAction?.exportExcel}
            handleReset={() => handleResetLoading('exportExcel')}
            handleButtonClick={handleButtonClick}
            {...restProps}
          />
        )}
        {hasNotifications && (
          <Notifications {...restProps} handleButtonClick={handleButtonClick} />
        )}
      </div>
    </div>
  )
}

TableDataHeader.propTypes = {
  handleButtonClick: PropTypes.func,
  hasAdd: PropTypes.bool,
  hasExport: PropTypes.bool,
  hasExcel: PropTypes.bool,
  hasExportPDF: PropTypes.bool,
  hasMonthNavigation: PropTypes.bool,
  hasSelectNavigation: PropTypes.bool,
  hasTitleDetails: PropTypes.bool,
  hasRefresh: PropTypes.bool,
  hasDownload: PropTypes.bool,
  hasRecharge: PropTypes.bool,
  hasVisibility: PropTypes.bool,
  hasMoveToFolder: PropTypes.bool,
  hasStatusChange: PropTypes.bool,
  hasNotifications: PropTypes.bool,
  hasMultifunctional: PropTypes.bool,
  hasMultifunctionalRight: PropTypes.bool,
  hasListButton: PropTypes.bool,
  hasDoubleButtons: PropTypes.bool,
  hasProcessCounter: PropTypes.bool,
}

TableDataHeader.default = {
  handleButtonClick: () => null,
  hasAdd: false,
  hasExport: false,
  hasExcel: false,
  hasExportPDF: false,
  hasMonthNavigation: false,
  hasSelectNavigation: false,
  hasTitleDetails: false,
  hasRefresh: false,
  hasDownload: false,
  hasRecharge: false,
  hasVisibility: false,
  hasMoveToFolder: false,
  hasStatusChange: false,
  hasNotifications: false,
  hasMultifunctional: false,
  hasMultifunctionalRight: false,
  hasListButton: false,
  hasDoubleButtons: false,
  hasProcessCounter: false,
}
