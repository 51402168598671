// OPERADORA: claro
import claroAlerta from './icons/claro_alerta.png'
import claroAnormal from './icons/claro_anormal.png'
import claroNormal from './icons/claro_normal.png'
// OPERADORA: conecta
import conectaAlerta from './icons/conecta_alerta.png'
import conectaAnormal from './icons/conecta_anormal.png'
import conectaNormal from './icons/conecta_normal.png'
// OPERADORA: ctbc
import ctbcAlerta from './icons/ctbc_alerta.png'
import ctbcAnormal from './icons/ctbc_anormal.png'
import ctbcNormal from './icons/ctbc_normal.png'
// OPERADORA: movistar
import movistarAlerta from './icons/movistar_alerta.png'
import movistarAnormal from './icons/movistar_anormal.png'
import movistarNormal from './icons/movistar_normal.png'
// OPERADORA: nextel
import nextelAlerta from './icons/nextel_alerta.png'
import nextelAnormal from './icons/nextel_anormal.png'
import nextelNormal from './icons/nextel_normal.png'
// OPERADORA: oi
import oiAlerta from './icons/oi_alerta.png'
import oiAnormal from './icons/oi_anormal.png'
import oiNormal from './icons/oi_normal.png'
// OPERADORA: telefonica
import telefonicaAlerta from './icons/telefonica_alerta.png'
import telefonicaAnormal from './icons/telefonica_anormal.png'
import telefonicaNormal from './icons/telefonica_normal.png'
// OPERADORA: tigo
import tigoAlerta from './icons/tigo_alerta.png'
import tigoAnormal from './icons/tigo_anormal.png'
import tigoNormal from './icons/tigo_normal.png'
// OPERADORA: tim
import timAlerta from './icons/tim_alerta.png'
import timAnormal from './icons/tim_anormal.png'
import timNormal from './icons/tim_normal.png'
// OPERADORA: vivo
import vivoAlerta from './icons/vivo_alerta.png'
import vivoAnormal from './icons/vivo_anormal.png'
import vivoNormal from './icons/vivo_normal.png'
// OPERADORA: LOGOS
import algarTelecom from './logos/algar_telecom.png'
import brasilTelecom from './logos/brasil_telecom.png'
import claro from './logos/claro.png'
import conecta from './logos/conecta.png'
import ctbc from './logos/ctbc.png'
import embratel from './logos/embratel.png'
import intelig from './logos/intelig.png'
import lyra from './logos/lyra.png'
import movistar from './logos/movistar.png'
import nlt from './logos/nlt.png'
import oi from './logos/oi.png'
import rapidaTelecom from './logos/rapida_telecom.png'
import telefonica from './logos/telefonica.png'
import tim from './logos/tim.png'
import vivo from './logos/vivo.png'

const icons = {
  claroAlerta,
  claroAnormal,
  claroNormal,
  conectaAlerta,
  conectaAnormal,
  conectaNormal,
  ctbcAlerta,
  ctbcAnormal,
  ctbcNormal,
  movistarAlerta,
  movistarAnormal,
  movistarNormal,
  nextelAlerta,
  nextelAnormal,
  nextelNormal,
  oiAlerta,
  oiAnormal,
  oiNormal,
  telefonicaAlerta,
  telefonicaAnormal,
  telefonicaNormal,
  tigoAlerta,
  tigoAnormal,
  tigoNormal,
  timAlerta,
  timAnormal,
  timNormal,
  vivoAlerta,
  vivoAnormal,
  vivoNormal,
}

const logos = {
  algarTelecom,
  brasilTelecom,
  claro,
  conecta,
  ctbc,
  embratel,
  intelig,
  lyra,
  movistar,
  nlt,
  oi,
  rapidaTelecom,
  telefonica,
  tim,
  vivo,
}

const getLogoById = (id) => {
  switch (id) {
    case 4:
      return intelig
    case 6:
      return claro
    case 7:
      return tim
    case 8:
      return oi
    case 9:
      return brasilTelecom
    case 10:
      return embratel
    case 12:
      return telefonica
    case 13:
      return vivo
    case 14:
      return rapidaTelecom
    case 21:
      return ctbc
    case 51:
      return movistar
    case 61:
      return conecta
    case 62:
      return algarTelecom
    case 82:
      return nlt
    default:
      return lyra
  }
}

const carriers = { icons, logos, getLogoById }

export { carriers }
